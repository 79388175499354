import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  EditButton,
  TopToolbar,
  // NumberInput,
  // SimpleForm,
  // FunctionField,
  // ArrayField,
  // Edit,
} from 'react-admin';

const ListActions = ({ basePath, ids, resource }) => {
  const [id] = ids;
  if (id) {
    const [settingId] = id.split('-');

    return (
      <TopToolbar>
        <EditButton
          basePath={basePath}
          record={{ id: settingId }}
          resource={resource}
        />
      </TopToolbar>
    );
  }

  return null;
};

export const SearchSettingsList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions />}
      sort={{ field: 'city', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="city" label="Miasto" sortable={false} />
        <NumberField
          source="numberOfRecords"
          label="Ilość wyświetlanych rekordów"
        />
      </Datagrid>
    </List>
    // <Edit {...props}>
    // <SimpleForm
    //   submitOnEnter={false}
    //   redirect={(basePath) => basePath}
    //   fullWidth
    // >
    //   <ArrayField source="data">
    //     <Datagrid fullWidth>
    //       <TextField source="city" label="Miasto" />
    //       <FunctionField
    //         label=""
    //         render={(record) => {

    //           return (
    //             <NumberInput
    //               label="Liczba wyświetlanych rekordów"
    //               source="numberOfRecords"
    //               id={record.id}
    //               min={1}
    //               step={1}
    //               fullWidth
    //             />
    //           );
    //         }}
    //       />
    //     </Datagrid>
    //   </ArrayField>
    // </SimpleForm>
    // </Edit>
  );
};

export default SearchSettingsList;
