import React from 'react';
import {
  Datagrid,
  List,
  CreateButton,
  TextField,
  FunctionField,
} from 'react-admin';
import { CardActions } from '@material-ui/core';
import { ROLES_KEYS } from '../../config/constants';
import { imageUrl } from '../../utils/imagePath';

const Actions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const ProductsList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Lista adresów"
    bulkActionButtons={false}
    actions={permissions === ROLES_KEYS.ADMIN ? <Actions /> : null}
    sort={{ field: 'city', order: 'DESC' }}
  >
    <Datagrid
      rowClick={permissions === ROLES_KEYS.ADMIN ? 'edit' : null}
      sx={{ '& .column-isPublished': { display: 'none' } }}
    >
      <TextField source="title" label="Tytuł" />
      <FunctionField
        source="image.thumbnails.mini"
        label="Obraz"
        render={(record) =>
          record &&
          record.image &&
          record.image.thumbnails &&
          record.image.thumbnails.mini ? (
            <img
              src={imageUrl(record.image.thumbnails.mini)}
              alt=""
              style={{ width: 100, height: 100, objectFit: 'contain' }}
            />
          ) : null
        }
      />
      <FunctionField
        source="isPublished"
        sortable
        label="Opublikowany"
        render={(record) =>
          record.isPublished ? (
            <img
              src="/images/icons/check.svg"
              style={{ width: 24, height: 24 }}
              alt=""
            />
          ) : (
            <img
              src="/images/icons/x.svg"
              style={{ width: 24, height: 24 }}
              alt=""
            />
          )
        }
      />
      <TextField source="createdAt" label="Data utworzenia" />
    </Datagrid>
  </List>
);

export default ProductsList;
