export default {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
  },
  elInRow: {
    borderTop: '1px rgba(0,0,0,.3) solid',
    borderBottom: '1px rgba(0,0,0,.3) solid',
    padding: '20px 16px',
    margin: '20px -16px',
    width: 'calc(100% + 32px)',
    backgroundColor: 'rgba(0,0,0,.02)',
    flexDirection: 'column !important',

    '& > label': {
      position: 'relative',
    },

    '& ul': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0 -5px',

      '& > li': {
        flexDirection: 'column',
        flex: '0 0 calc(25% - 10px)',
        padding: '10px',
        backgroundColor: 'rgba(0,0,0,.02)',
        borderRadius: '5px',
        margin: '5px',
        border: '1px #dadada dashed',

        '& > p': {
          display: 'none',
        },

        '&:last-child': {
          flex: '1 0 100%',
          marginLeft: 0,
          backgroundColor: 'transparent',
          justifyContent: 'flex-end',
          border: 'none',
        },
      },
    },
  },
  formTabs: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  multipleInput: {
    display: 'none',
  },
};
