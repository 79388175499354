export function getStylesForBooking(booking) {
  const result = {};

  if (booking.isRepeated) {
    result.backgroundColor = '#ffbf00';
  }

  if (booking.isAdminOrder && booking.paymentState !== 'PROCESSING') {
    result.backgroundColor = '#751818';
    result.color = '#fff';
  }

  if (booking.paymentState === 'PROCESSING') {
    result.backgroundColor = '#eaeaea';
  }

  return result;
}

export function getStylesForCell(day, isAvailable) {
  const result = {};

  if (
    day.dateString === 'Sobota' ||
    day.dateString === 'Niedziela' ||
    day.isHoliday
  ) {
    result.backgroundColor = '#fbfbfb';
  }

  if (!isAvailable) {
    result.backgroundColor = 'rgba(224, 224, 224, .55)';
  }

  return result;
}
