import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  required,
  BooleanInput,
  ImageInput,
  SelectInput,
} from 'react-admin';
import slugify from 'slugify';
import RichTextInput from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core/styles';
import { FormSpy } from 'react-final-form';
import NavigationPrompt from 'react-router-navigation-prompt';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';
import formStyles from '../../components/FormStyles';
import ImageFromApiField from '../Rooms/components/ImageFromApiField';
import DeleteButtonWithConfirmation from '../../layout/DeleteButtonWithConfirmation';
import { COUNTRIES } from '../../config/constants';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const BlogPostFormToolbar = withStyles(styles)(
  ({ dispatch, classes, kind, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" {...props} />
      {kind === 'edit' && <DeleteButtonWithConfirmation {...props} />}
    </Toolbar>
  ),
);

let debounce;

const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

export const BlogPostForm = withStyles(formStyles)(
  ({ classes, kind, ...props }) => {
    const WrapperComponent = kind === 'edit' ? Edit : Create;

    if (kind === 'edit') {
      props.undoable = false;
    }

    return (
      <div className="overflow-visible">
        <WrapperComponent
          {...props}
          title={
            kind === 'edit' ? 'Edycja artykułu' : 'Tworzenie nowego artykułu'
          }
        >
          <SimpleForm
            submitOnEnter={false}
            initialValues={{ isPublished: false }}
            toolbar={<BlogPostFormToolbar kind={kind} />}
            redirect={(basePath) => basePath}
          >
            {kind === 'edit' ? (
              <FormSpy
                subscription={{
                  modified: true,
                }}
              >
                {(spyProps) => (
                  <>
                    <NavigationPrompt when={isAnyModified(spyProps.modified)}>
                      {({ onConfirm, onCancel }) => (
                        <ConfirmNavigationModal
                          when
                          onCancel={onCancel}
                          onConfirm={onConfirm}
                        />
                      )}
                    </NavigationPrompt>
                  </>
                )}
              </FormSpy>
            ) : null}
            <SelectInput
              fullWidth
              source="store"
              label="Kraj"
              validate={[required()]}
              choices={Object.keys(COUNTRIES).map((c) => ({
                id: c,
                name: COUNTRIES[c],
              }))}
            />
            <TextInput
              label="Tytuł"
              source="title"
              validate={[required()]}
              fullWidth
            />
            <FormSpy>
              {({ values, form: { change } }) => {
                if (values.title) {
                  clearTimeout(debounce);
                  debounce = setTimeout(() => {
                    change('slug', slugify(values.title, { lower: true }));
                  }, 300);
                }
                return null;
              }}
            </FormSpy>
            <TextInput
              label="Slug"
              source="slug"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              label="Skrót wyświetlany na liście"
              source="shortDescription"
              validate={[required()]}
              fullWidth
              multiline
            />
            <RichTextInput
              label="Treść"
              source="content"
              styles={{ minHeight: 300 }}
              validation={{ required: true }}
            />
            <ImageInput
              source="image"
              label="Obraz"
              accept="image/*"
              validate={[required()]}
            >
              <ImageFromApiField source="src" title="name" />
            </ImageInput>
            <BooleanInput label="Opublikowany" source="isPublished" />
          </SimpleForm>
        </WrapperComponent>
      </div>
    );
  },
);

export default BlogPostForm;
