import React from 'react';
import {
  required,
  maxLength,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));

const countryList = countries.getNames('pl', { select: 'official' });

const COUNTRY_SELECT_OPTIONS = Object.keys(countryList).map((key) => ({
  id: key.toLowerCase(),
  name: countryList[key],
}));

export const BillingForm = ({ data, store }) => {
  if (data.billingAddress) {
    return (
      <div
        style={{
          margin: '20px -16px',
          padding: '16px',
          backgroundColor: 'rgba(0,0,0,.02)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ margin: 0, fontSize: '18px', fontWeight: 'normal' }}>
          Dane billingowe
        </h3>
        <RadioButtonGroupInput
          source="billingAddress.type"
          label="Typ konta"
          required
          disabled={data.billingAddress.type}
          initialValue={
            (data.billingAddress.type && data.billingAddress.type) || 'private'
          }
          fullWidth
          choices={[
            {
              id: 'private',
              name: 'Osoba prywatna',
            },
            { id: 'company', name: 'Firma' },
          ]}
        />
        <TextInput
          label="Imię"
          source="billingAddress.firstName"
          validate={[required()]}
          fullWidth
          disabled={data.billingAddress.firstName}
          initialValue={
            (data.billingAddress.firstName && data.billingAddress.firstName) ||
            ''
          }
        />
        <TextInput
          label="Nazwisko"
          source="billingAddress.lastName"
          disabled={data.billingAddress.lastName}
          initialValue={
            (data.billingAddress.lastName && data.billingAddress.lastName) || ''
          }
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress.type === 'company' && (
                <TextInput
                  label="Nazwa firmy"
                  source="billingAddress.companyName"
                  disabled={data.billingAddress.companyName}
                  initialValue={
                    (data.billingAddress.companyName &&
                      data.billingAddress.companyName) ||
                    ''
                  }
                  validate={
                    formData.billingAddress.type === 'company' && [required()]
                  }
                />
              )}
            </>
          )}
        </FormDataConsumer>

        <SelectInput
          source="billingAddress.country"
          choices={COUNTRY_SELECT_OPTIONS}
          label="Kraj"
          disabled={data.billingAddress.country}
          validate={[required()]}
          defaultValue={
            (data.billingAddress.country &&
              data.billingAddress.country.toLowerCase()) ||
            'pl'
          }
        />
        <TextInput
          label="Miasto"
          source="billingAddress.city"
          disabled={data.billingAddress.city}
          validate={[required()]}
          initialValue={
            (data.billingAddress.city && data.billingAddress.city) || ''
          }
        />
        <TextInput
          label="Ulica"
          source="billingAddress.street"
          disabled={data.billingAddress.street}
          validate={[required()]}
          initialValue={
            (data.billingAddress.street && data.billingAddress.street) || ''
          }
        />
        <TextInput
          label="Numer ulicy"
          source="billingAddress.streetNumber"
          disabled={data.billingAddress.streetNumber}
          validate={[required()]}
          initialValue={
            (data.billingAddress.streetNumber &&
              data.billingAddress.streetNumber) ||
            ''
          }
        />
        <TextInput
          label="Numer mieszkania"
          source="billingAddress.flatNumber"
          disabled={data.billingAddress.flatNumber}
          initialValue={
            (data.billingAddress.flatNumber &&
              data.billingAddress.flatNumber) ||
            ''
          }
        />
        <TextInput
          label="Kod pocztowy"
          source="billingAddress.zipCode"
          validate={[
            store === 'pl'
              ? required()
              : maxLength(10, 'Długość maksymalna kodu pocztowego wynosi 10.'),
          ]}
          disabled={data.billingAddress.zipCode}
          initialValue={
            (data.billingAddress.zipCode && data.billingAddress.zipCode) || ''
          }
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress.type === 'company' && (
                <RadioButtonGroupInput
                  source="billingAddress.businessType"
                  label="Typ firmy"
                  required
                  disabled={data.billingAddress.businessType}
                  initialValue={
                    (data.billingAddress.businessType &&
                      data.billingAddress.businessType) ||
                    'self_employed'
                  }
                  choices={[
                    {
                      id: 'self_employed',
                      name: 'Jednoosobowa działalność gospodarcza',
                    },
                    { id: 'otherBusiness', name: 'Inny rodzaj działalności' },
                  ]}
                />
              )}
            </>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress.type === 'company' && (
                <TextInput
                  label="NIP"
                  disabled={data.billingAddress.vatNumber}
                  initialValue={
                    (data.billingAddress.vatNumber &&
                      data.billingAddress.vatNumber) ||
                    ''
                  }
                  validate={
                    formData.billingAddress.type === 'company' && [required()]
                  }
                  source="billingAddress.vatNumber"
                />
              )}
            </>
          )}
        </FormDataConsumer>
      </div>
    );
  }
  return (
    <div
      style={{
        margin: '20px -16px',
        padding: '16px',
        backgroundColor: 'rgba(0,0,0,.02)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h3 style={{ margin: 0, fontSize: '18px', fontWeight: 'normal' }}>
        Dane billingowe
      </h3>
      <RadioButtonGroupInput
        source="billingAddress.type"
        label="Typ konta"
        required
        initialValue="private"
        choices={[
          {
            id: 'private',
            name: 'Osoba prywatna',
          },
          { id: 'company', name: 'Firma' },
        ]}
      />
      <TextInput
        label="Imię"
        source="billingAddress.firstName"
        validate={[required()]}
        initialValue=""
      />
      <TextInput
        label="Nazwisko"
        source="billingAddress.lastName"
        validate={[required()]}
        initialValue=""
      />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.billingAddress.type === 'company' && (
              <TextInput
                label="Nazwa firmy"
                source="billingAddress.companyName"
                validate={
                  formData.billingAddress.type === 'company' && [required()]
                }
                initialValue=""
              />
            )}
          </>
        )}
      </FormDataConsumer>

      <SelectInput
        source="billingAddress.country"
        choices={COUNTRY_SELECT_OPTIONS}
        label="Kraj"
        validate={[required()]}
        defaultValue="pl"
      />
      <TextInput
        label="Miasto"
        source="billingAddress.city"
        validate={[required()]}
        initialValue=""
      />
      <TextInput
        label="Ulica"
        source="billingAddress.street"
        validate={[required()]}
        initialValue=""
      />
      <TextInput
        label="Numer ulicy"
        source="billingAddress.streetNumber"
        validate={[required()]}
        initialValue=""
      />
      <TextInput label="Numer mieszkania" source="billingAddress.flatNumber" />
      <TextInput
        label="Kod pocztowy"
        source="billingAddress.zipCode"
        validate={[
          store === 'pl'
            ? required()
            : maxLength(10, 'Długość maksymalna kodu pocztowego wynosi 10.'),
        ]}
        initialValue=""
      />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.billingAddress.type === 'company' && (
              <RadioButtonGroupInput
                source="billingAddress.businessType"
                label="Typ firmy"
                required
                initialValue="self_employed"
                choices={[
                  {
                    id: 'self_employed',
                    name: 'Jednoosobowa działalność gospodarcza',
                  },
                  { id: 'otherBusiness', name: 'Inny rodzaj działalności' },
                ]}
              />
            )}
          </>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.billingAddress.type === 'company' && (
              <TextInput
                label="NIP"
                validate={
                  formData.billingAddress.type === 'company' && [required()]
                }
                initialValue=""
                source="billingAddress.vatNumber"
              />
            )}
          </>
        )}
      </FormDataConsumer>
    </div>
  );
};

export default BillingForm;
