import React from 'react';
import { FormSpy } from 'react-final-form';
import { SelectInput, required } from 'react-admin';

const MainImageFieldOptions = () => (
  <FormSpy subscription={{ values: true }}>
    {({ values: { images } }) => {
      if (images && images.length) {
        return (
          <SelectInput
            source="primaryImageIndex"
            label="Główne zdjęcie"
            validate={[required()]}
            choices={images.map((image, index) => ({
              id: index,
              name: image.name,
            }))}
          />
        );
      }
      return null;
    }}
  </FormSpy>
);

export default MainImageFieldOptions;
