import React from 'react';
import {
  NumberInput,
  SimpleForm,
  ArrayInput,
  Edit,
  Toolbar,
  SaveButton,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const formStyles = {
  list: {
    li: {
      display: 'none',
    },
  },
  row: {
    display: 'flex',
  },
};

const ToolbarButtons = withStyles(styles)(({ dispatch, classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton label="Zapisz" {...props} />
  </Toolbar>
));

export const SearchSettingsForm = ({ classes, permissions, ...props }) => {
  return (
    <Edit {...props} actions={false}>
      <SimpleForm
        submitOnEnter={false}
        redirect={(basePath) => basePath}
        toolbar={<ToolbarButtons />}
      >
        <ArrayInput source="cities" label="">
          <SimpleFormIterator
            fullWidth
            disableAdd
            disableReordering
            disableRemove
            disableClear
            className="input-list"
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => (
                <strong source={getSource('city')}>
                  {scopedFormData.city}
                </strong>
              )}
            </FormDataConsumer>
            <NumberInput
              label="Liczba wyświetlanych rekordów"
              source="numberOfRecords"
              min={1}
              step={1}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default withStyles(formStyles)(SearchSettingsForm);
