import React, { Component } from 'react';
import { required, SimpleForm } from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import { FormSpy } from 'react-final-form';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { DateTimeInput } from '../../components/DateInputs';
import BillingForm from './BillingForm';

require('../../components/CloneModal/Mui.css');

const dateTimeInputOptions = {
  ampm: false,
  keyboard: 'true',
  minutesStep: 30,
  mask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /[30]/,
    /0/,
  ],
  format: 'dd.MM.yyyy HH:mm:00',
  cancelLabel: 'Anuluj',
};

const minTimeRangeValidate = (value, allValues) => {
  const diff = new Date(value) - new Date(allValues.from);
  if (diff < 60 * 60 * 1000) {
    return 'Rezerwacja musi trwać przynajmniej 1 godzinę';
  }
  return undefined;
};

const toDateTimeValidate = [required(), minTimeRangeValidate];

class FormInside extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const { setFormState, booking } = this.props;

    const date = booking && moment(booking.from).add(1, 'd');
    const dateTo = booking && moment(booking.to).add(1, 'd');

    return (
      <span>
        <FormSpy
          subscription={{ values: true, valid: true, modified: true }}
          onChange={(state) => {
            setFormState(state);
          }}
        />
        <div>
          <BillingForm data={booking} />

          <div className="clone-modal">
            <DateTimeInput
              source="from"
              label="Od"
              options={dateTimeInputOptions}
              validate={[required()]}
              initialValue={date}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <DateTimeInput
                  source="to"
                  validate={toDateTimeValidate}
                  initialValue={dateTo}
                  label="Do"
                  options={{
                    ...dateTimeInputOptions,
                    minDate: formData.from,
                  }}
                  {...rest}
                />
              )}
            </FormDataConsumer>
          </div>
        </div>
      </span>
    );
  }
}

const Form = ({ setFormState, ...props }) => {
  return (
    <SimpleForm
      {...props}
      submitOnEnter={false}
      redirect={(basePath) => basePath}
      toolbar={null}
    >
      <FormInside setFormState={setFormState} {...props} />
    </SimpleForm>
  );
};

export default Form;
