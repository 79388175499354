import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: apiUrl,
  method: 'get',
  timeout: 35000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiRoutes = {
  auth: {
    signUp: '/auth/sign_up',
    signIn: '/auth/sign_in',
    profile: '/users/me',
  },
};

export { API, apiRoutes, apiUrl };
