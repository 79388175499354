import React from 'react';
import {
  Create,
  ReferenceInput,
  required,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  NumberInput,
  Query,
  Loading,
  minValue,
  useQuery,
} from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import { DateTimeInput } from '../../components/DateInputs';
import BillingForm from './BillingForm';

const dateTimeInputOptions = {
  ampm: false,
  keyboard: 'true',
  minutesStep: 30,
  mask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /[30]/,
    /0/,
  ],
  format: 'dd.MM.yyyy HH:mm:00',
  cancelLabel: 'Anuluj',
};

const minTimeRangeValidate = (value, allValues) => {
  const diff = new Date(value) - new Date(allValues.from);
  if (diff < 60 * 60 * 1000) {
    return 'Rezerwacja musi trwać przynajmniej 1 godzinę';
  }
  return undefined;
};

const toDateTimeValidate = [required(), minTimeRangeValidate];

const BookingsCreate = (props) => {
  return (
    <Create {...props} title="Stwórz rezerwację">
      <SimpleForm submitOnEnter={false} redirect={(basePath) => basePath}>
        <ReferenceInput
          label="Nazwa sali"
          source="bookableObjectId"
          reference="inventory"
          validate={[required()]}
        >
          <AutocompleteInput
            optionText={(choice) => {
              if (choice && choice.name) {
                return `${choice.name}, ${choice.location.city}, ${choice.location.street}`;
              }
              return choice
                ? `${choice.location.city}, ${choice.location.street}`
                : null;
              // eslint-disable-next-line react/jsx-curly-newline
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Użytkownik"
          source="userId"
          reference="users"
          link={false}
          validate={[required()]}
          perPage={999}
        >
          <AutocompleteInput
            optionText={(choice) => {
              if (choice && choice.name) {
                return `${choice.name || ''} (${choice.email}) `;
              }
              if (
                choice &&
                choice.billingAddress &&
                choice.billingAddress.firstName &&
                choice.billingAddress.lastName
              ) {
                return `${choice.billingAddress.firstName} ${choice.billingAddress.lastName} (${choice.email}) `;
              }
              return choice ? `(${choice.email}) ` : null;
              // eslint-disable-next-line react/jsx-curly-newline
            }}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.userId === undefined) {
              return (
                <>
                  <div style={{ marginBottom: '20px' }}>
                    Wybierz użytkownika by pokazać dane billingowe
                  </div>
                </>
              );
            }
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { data: dataRoom, loading: isLoadingRoom } = useQuery({
              type: 'getOne',
              resource: 'inventory',
              payload: { id: formData && formData.bookableObjectId },
            });
            return (
              <Query
                type="getOne"
                resource="users"
                payload={{ id: formData.userId }}
              >
                {({ data, loading, error }) => {
                  if (loading || isLoadingRoom) {
                    return <Loading />;
                  }
                  if (error) {
                    return <p>ERROR {error}</p>;
                  }
                  if (data) {
                    return (
                      <BillingForm
                        data={data}
                        store={dataRoom ? dataRoom.store : 'pl'}
                      />
                    );
                  }
                  return null;
                }}
              </Query>
            );
          }}
        </FormDataConsumer>
        <TextInput label="Opis" source="description" />
        <DateTimeInput
          source="from"
          label="Od"
          options={dateTimeInputOptions}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <DateTimeInput
              source="to"
              validate={toDateTimeValidate}
              label="Do"
              options={{
                ...dateTimeInputOptions,
                minDate: formData.from,
                minutesStep: 30,
              }}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <NumberInput
          source="numberOfUsers"
          label="Ilość osób"
          validate={[required(), minValue(1)]}
          defaultValue="1"
          min="1"
        />
      </SimpleForm>
    </Create>
  );
};

export default BookingsCreate;
