import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { Loading } from 'react-admin';
import isEqual from 'lodash/isEqual';

import BookingCell from './BookingCell';
import BookingModal from '../../../components/BookingModal';
import CloneModal from '../../../components/CloneModal';
import { formatDate, isToday } from '../utils/matrixHelpers';

const Dummy = (props) => React.Children.only(props.children);

const styles = {
  tableRow: {
    height: '10px',
  },
  tableCell: {
    padding: '8px',
    whiteSpace: 'nowrap',
    position: 'relative',
    height: '40px',
  },
  holidayOverlay: {
    position: 'absolute',
    left: '2px',
    top: '2px',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
    zIndex: 2,
    border: '2px solid #f76a26',
  },
  tableHeaderText: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '11px',
    display: 'block',
    textAlign: 'center',
    zIndex: 3,
  },
  tableHeaderDate: {
    display: 'block',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '18px',
    position: 'relative',
    zIndex: 3,
  },
  borderCutter: {
    position: 'absolute',
    display: 'block',

    zIndex: 1,
    height: '70%',
    width: '10px',
    right: '-1px',
    top: 0,
    background: 'rgb(255,255,255)',
    // eslint-disable-next-line
    background:
      'linear-gradient(-180deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%)',
  },
};

const initialState = {
  bookingModalVisible: false,
  cloneModalVisible: false,
  room: null,
  date: null,
  time: null,
  booking: null,
};

class MatrixGrid extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { loaded, data } = this.props;
    return (
      !isEqual(loaded, nextProps.loaded) ||
      data !== nextProps.data ||
      !isEqual(this.state, nextState)
    );
  }

  openBookingModal = (room, date, time) => {
    this.setState({
      bookingModalVisible: true,
      room,
      date,
      time,
    });
  };

  closeModal = () => {
    this.setState(initialState);
  };

  openCloneModal = (booking) => {
    this.setState({
      cloneModalVisible: true,
      booking,
    });
  };

  render() {
    const { classes, ids, data, filterValues: filter, location } = this.props;
    const {
      bookingModalVisible,
      room,
      date,
      time: stateTime,
      cloneModalVisible,
      booking,
    } = this.state;

    if (!ids.length) {
      return (
        <div style={{ padding: '30px' }}>
          <Loading />
        </div>
      );
    }
    if (!filter.to || !filter.from) {
      return (
        <div style={{ padding: '30px' }}>Proszę uzupełnić przedział dat.</div>
      );
    }

    return (
      <Paper>
        {bookingModalVisible ? (
          <BookingModal
            room={room}
            day={date}
            time={stateTime}
            onCloseHandler={this.closeModal}
          />
        ) : null}
        {cloneModalVisible ? (
          <CloneModal booking={booking} onCloseHandler={this.closeModal} />
        ) : null}
        <TableContainer
          style={{
            height: 'calc(100vh - 135px)',
            maxWidth: '100vw',
            overflow: 'auto',
          }}
        >
          <Table
            stickyHeader
            padding="normal"
            style={{
              position: 'relative',
              borderCollapse: 'collapse',
              margin: '20px',
              width: 'calc(100% - 40px)',
            }}
          >
            {ids.map((id, idx) => (
              <Dummy key={id}>
                <>
                  {idx === 0 ? (
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: '150px',
                            borderRight: '1px rgba(0,0,0, .08) solid',
                            backgroundColor: '#fff',
                          }}
                          colSpan={2}
                        >
                          <span className={classes.borderCutter} />
                        </TableCell>
                        {data[id].bookingsByDay.map((day) => (
                          <TableCell
                            key={`matrix-table-header_${day.date}`}
                            style={{
                              borderRight: '1px rgba(0,0,0, .08) solid',
                              paddingBottom: '3px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <span
                              className={classes.tableHeaderText}
                              style={{ color: isToday(day.date) && '#ff7700' }}
                            >
                              {day.dateString}
                            </span>
                            <span
                              className={classes.tableHeaderDate}
                              style={{ color: isToday(day.date) && '#ff7700' }}
                            >
                              {formatDate(day.date)}
                            </span>
                            {day.isHoliday && (
                              <span className={classes.holidayOverlay} />
                            )}
                            <span className={classes.borderCutter} />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  ) : null}
                  <TableBody style={{ marginBottom: '20px' }}>
                    {data[id].hours.map((time, index, range) => (
                      <TableRow key={time.time} className={classes.tableRow}>
                        {index === 0 && (
                          <TableCell
                            className={classes.tableCell}
                            rowSpan={range.length}
                            style={{
                              background: data[id].color,
                              width: '50px',
                              minWidth: '50px',
                            }}
                          >
                            <div
                              style={{
                                transform: 'rotate(-180deg) translateY(50%)',
                                whiteSpace: 'nowrap',
                                position: 'absolute',
                                top: '50%',
                                left: '11px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                writingMode: 'tb-rl',
                                backgroundColor: '#fff',
                                padding: '5px 5px',
                                borderRadius: '3px',
                              }}
                            >
                              {data[id].name}
                            </div>
                          </TableCell>
                        )}
                        <TableCell
                          className={classes.tableCell}
                          style={{
                            width: '60px',
                            textAlign: 'center',
                            borderRight: '1px rgba(0,0,0, .1) solid',
                          }}
                        >
                          {time.text}
                        </TableCell>
                        {data[id].bookingsByDay.map((day, dayIndex) => (
                          <BookingCell
                            key={`matrix-cell__${day.date}`}
                            dayIndex={dayIndex}
                            time={time}
                            day={day}
                            id={id}
                            className={classes.tableCell}
                            room={data[id]._id}
                            openBookingModal={this.openBookingModal}
                            openCloneModal={this.openCloneModal}
                            location={location}
                          />
                        ))}
                      </TableRow>
                    ))}
                    <tr>
                      <td colSpan={10} style={{ height: '20px' }} />
                    </tr>
                  </TableBody>
                </>
              </Dummy>
            ))}
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

MatrixGrid.defaultProps = {
  data: {},
  ids: [],
  filterValues: {},
};

export default withStyles(styles)(MatrixGrid);
