import React from 'react';
import {
  Datagrid,
  List,
  // ExportButton,
  CreateButton,
  TextField,
  Filter,
  AutocompleteInput,
  FunctionField,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CardActions } from '@material-ui/core';
import { ROLES, ROLES_KEYS } from '../../config/constants';

const useStyles = makeStyles({
  icon: {
    height: 16,
    width: 16,
    display: 'block',
  },
});

const ConvertToPolishRole = (record) => {
  switch (record.role) {
    case 'USER':
      return 'Użytkownik';
    case 'PREMIUM_USER':
      return 'Użytkownik premium';
    case 'ADMIN':
      return 'Admin';
    case 'ROOM_VIEWER':
      return 'Menedżer';
    default:
      return 'Użytkownik';
  }
};

const RenderPremiumIcon = (record) => {
  const classes = useStyles();
  return record.isPremium ? (
    <img src="/images/icons/diamond.svg" className={classes.icon} alt="" />
  ) : null;
};

const UserName = (record) => {
  if (
    record &&
    record.billingAddress &&
    record.billingAddress.firstName &&
    record.billingAddress.lastName
  ) {
    return `${record.billingAddress.firstName} ${record.billingAddress.lastName}`;
  }
  if (record.name) {
    return record.name;
  }
  return '';
};

const UserListActions = ({
  basePath,
  // currentSort,
  displayedFilters,
  // exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  // total,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
  </CardActions>
);

const RoleFilters = (props) => (
  <Filter {...props}>
    <AutocompleteInput
      source="role"
      choices={[{ id: '', name: 'Wszystkie' }, ...ROLES]}
      label="Rola"
      alwaysOn
    />
    <TextInput source="name" label="Nazwa użytkownika" alwaysOn />
    <TextInput source="email" label="Email użytkownika" alwaysOn />
    <TextInput source="fbUsername" label="Nazwa na Facebooku" alwaysOn />
    <TextInput source="vatNumber" label="NIP" alwaysOn />
  </Filter>
);

export const UsersList = ({ permissions, ...props }) => {
  if (permissions === ROLES_KEYS.ADMIN) {
    return (
      <List
        {...props}
        filters={<RoleFilters />}
        title="Lista użytkowników"
        bulkActionButtons={false}
        actions={<UserListActions />}
        sort={{
          field: 'numberOfOrders',
          order: 'DESC',
        }}
      >
        <Datagrid rowClick="edit">
          <FunctionField
            source="user"
            label="Nazwa użytkownika"
            sortable={false}
            render={UserName}
          />
          <FunctionField
            source="isPremium"
            label="Premium"
            sortable
            render={RenderPremiumIcon}
          />
          <TextField source="fbUsername" label="Nazwa na FB" sortable={false} />
          <TextField source="email" label="Email" sortable={false} />
          <TextField source="phone" label="Numer telefonu" sortable={false} />
          <TextField source="numberOfOrders" label="Liczba rezerwacji" />
          <TextField
            source="billingAddress.vatNumber"
            label="NIP"
            sortable={false}
          />
          <FunctionField
            source="role"
            render={ConvertToPolishRole}
            label="Rola"
            sortable={false}
          />
        </Datagrid>
      </List>
    );
  }
  return (
    <div style={{ textAlign: 'center' }}>
      Twoja rola, nie ma dostępu do tego widoku.
    </div>
  );
};

export default UsersList;
