import React, { Component } from 'react';
import {
  required,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  Query,
  Loading,
  useQuery,
  minValue,
} from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import { FormSpy } from 'react-final-form';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { DateTimeInput } from '../../components/DateInputs';
import BillingForm from './BillingForm';

require('../../components/BookingModal/Mui.css');

const dateTimeInputOptions = {
  ampm: false,
  keyboard: 'true',
  minutesStep: 30,
  mask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /[30]/,
    /0/,
  ],
  format: 'dd.MM.yyyy HH:mm:00',
  cancelLabel: 'Anuluj',
};

const minTimeRangeValidate = (value, allValues) => {
  const diff = new Date(value) - new Date(allValues.from);
  if (diff < 60 * 60 * 1000) {
    return 'Rezerwacja musi trwać przynajmniej 1 godzinę';
  }
  return undefined;
};

const toDateTimeValidate = [required(), minTimeRangeValidate];

class FormInside extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const { setFormState, room, day, time } = this.props;

    const date =
      day &&
      moment(day.date)
        .subtract(1, 'day')
        .add(time.time + 60, 'seconds')
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toDate();

    const toDate = moment(date)
      .add('hours', 1)
      .toDate();

    return (
      <>
        <FormSpy
          subscription={{ values: true, valid: true, modified: true }}
          onChange={(state) => {
            setFormState(state);
          }}
        />
        <ReferenceInput
          label="Nazwa sali"
          source="bookableObjectId"
          reference="inventory"
          validate={[required()]}
          defaultValue={room}
          perPage={999}
        >
          <AutocompleteInput
            defaultValue={room}
            optionText={(choice) => {
              if (choice && choice.name) {
                return `${choice.name}, ${choice.location.city}, ${choice.location.street}`;
              }
              return choice
                ? `${choice.location.city}, ${choice.location.street}`
                : null;
              // eslint-disable-next-line react/jsx-curly-newline
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Użytkownik"
          source="userId"
          reference="users"
          link={false}
          validate={[required()]}
          perPage={999}
        >
          <AutocompleteInput
            optionText={(choice) => {
              if (
                choice &&
                choice.billingAddress &&
                choice.billingAddress.firstName &&
                choice.billingAddress.lastName
              ) {
                return `${choice.billingAddress.firstName} ${choice.billingAddress.lastName} (${choice.email})`;
              }
              return choice
                ? `${choice.name ||
                    (choice.billingAddress &&
                      choice.billingAddress.name &&
                      `${choice.billingAddress.name}`)} (${choice.email})`
                : '-';
            }}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.userId === undefined) {
              return (
                <>
                  <div style={{ marginBottom: '20px' }}>
                    Wybierz użytkownika by pokazać dane billingowe
                  </div>
                </>
              );
            }
            const { data: dataRoom, loading: isLoadingRoom } = useQuery({
              type: 'getOne',
              resource: 'inventory',
              payload: { id: formData.bookableObjectId },
            });
            return (
              <Query
                type="getOne"
                resource="users"
                payload={{ id: formData.userId }}
              >
                {({ data, loading, error }) => {
                  if (loading || isLoadingRoom) {
                    return <Loading />;
                  }
                  if (error) {
                    return <p>ERROR {error}</p>;
                  }
                  if (data) {
                    return <BillingForm data={data} store={dataRoom.store} />;
                  }
                  return null;
                }}
              </Query>
            );
          }}
        </FormDataConsumer>
        <TextInput label="Opis" source="description" />
        <span className="booking-dates">
          <DateTimeInput
            source="from"
            label="Od"
            options={dateTimeInputOptions}
            validate={[required()]}
            initialValue={date}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <DateTimeInput
                source="to"
                validate={toDateTimeValidate}
                label="Do"
                initialValue={toDate}
                options={{
                  ...dateTimeInputOptions,
                  minDate: formData.from,
                }}
                {...rest}
              />
            )}
          </FormDataConsumer>
        </span>
        <NumberInput
          source="numberOfUsers"
          label="Ilość osób"
          validate={[required(), minValue(1)]}
          defaultValue="1"
          min="1"
        />
      </>
    );
  }
}

const Form = ({ setFormState, ...props }) => {
  return (
    <SimpleForm
      {...props}
      submitOnEnter={false}
      redirect={(basePath) => basePath}
      toolbar={null}
    >
      <FormInside setFormState={setFormState} {...props} />
    </SimpleForm>
  );
};

export default Form;
