/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  // GET_LIST,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { format, addDays } from 'date-fns';
import { FormDataConsumer } from 'ra-core';
import { COUNTRIES } from '../../../config/constants';
import { DateInput } from '../../../components/DateInputs';
import { isAdmin } from '../../../utils/roles';

const CountryHelper = ({ formData, ...rest }) => {
  const form = useForm();

  return (
    <SelectInput
      source="store"
      choices={[
        { id: '', name: 'Wszystkie' },
        ...Object.keys(COUNTRIES).map((c) => ({
          id: c,
          name: COUNTRIES[c],
        })),
      ]}
      onChange={() => {
        form.change('addressId', null);
      }}
      label="Kraj"
      alwaysOn
      {...rest}
    />
  );
};

const LocalizationHelper = ({ formData, ...rest }) => {
  const form = useForm();

  return (
    <ReferenceInput
      source="addressId"
      reference="addresses"
      label="Lokalizacja"
      filter={{ country: formData.store, active: true }}
      onChange={() => {
        form.change('bookableObjectId', null);
      }}
      alwaysOn
      perPage={999}
      {...rest}
    >
      <AutocompleteInput
        optionText={(choice) => {
          if (choice) {
            return `${choice.city}` === null || `${choice.city}` === 'undefined'
              ? 'Wszystkie'
              : `${choice.city}, ${choice.street}`;
          }
          return null;
        }}
      />
    </ReferenceInput>
  );
};

const TimeHelper = ({ formData, ...rest }) => {
  const formTime = useForm();

  return (
    <DateInput
      source="from"
      label="Od"
      parse={(a) => {
        formTime.change(
          'to',
          format(addDays(new Date(a), 7), 'yyyy-MM-dd 23:59:59'),
        );
        return a;
      }}
      options={{
        format: 'dd.MM.yyyy',
        cancelLabel: 'Anuluj',
      }}
      alwaysOn
      {...rest}
    />
  );
};

const MatrixFilters = (props) => (
  <Filter {...props}>
    <FormDataConsumer form="filterForm" alwaysOn source="from">
      {(formDataProps) => {
        // eslint-disable-next-line no-unused-expressions
        return <TimeHelper {...formDataProps} />;
      }}
    </FormDataConsumer>
    {props.filterValues.from && (
      <DateInput
        source="to"
        label="Do"
        filter={{ to: props.filterValues.to }}
        alwaysOn
        options={{
          format: 'dd.MM.yyyy',
          minDate: props.filterValues.from,
          cancelLabel: 'Anuluj',
        }}
        parse={(a) => {
          const foramtedDate = format(new Date(a), 'yyyy-MM-dd 23:59:59');
          return foramtedDate;
        }}
      />
    )}

    {isAdmin && (
      <FormDataConsumer form="filterForm" alwaysOn source="store">
        {(formDataProps) => <CountryHelper {...formDataProps} />}
      </FormDataConsumer>
    )}
    {isAdmin && (
      <FormDataConsumer form="filterForm" alwaysOn source="addressId">
        {(formDataProps) => <LocalizationHelper {...formDataProps} />}
      </FormDataConsumer>
    )}

    {props.filterValues.addressId && (
      <ReferenceInput
        source="bookableObjectId"
        reference="inventory"
        label="Nazwa sali"
        filter={{ addressId: props.filterValues.addressId }}
        alwaysOn
        perPage={999}
      >
        <AutocompleteInput
          optionText={(choice) => {
            if (!choice || choice.id === null || choice.name === 'undefined') {
              return 'Wszystkie';
            }

            return choice.name;
          }}
        />
      </ReferenceInput>
    )}
  </Filter>
);

export default MatrixFilters;
