import React from 'react';
import {
  Create,
  required,
  SimpleForm,
  SelectInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  TextInput,
  minValue,
} from 'react-admin';
import { format } from 'date-fns';
import {
  convertPriceToPLN,
  convertPriceToGR,
} from '../../utils/priceConverter';
import { DateInput } from '../../components/DateInputs';
import { DISCOUNT_TYPE, CURRENCY } from '../../config/constants';

const dateInputOptions = {
  ampm: false,
  keyboard: 'true',
  mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
  format: 'dd.MM.yyyy',
  cancelLabel: 'Anuluj',
};

const DiscountsCreate = (props) => (
  <Create {...props} title="Stwórz nowy adres">
    <SimpleForm submitOnEnter={false} redirect={(basePath) => basePath}>
      <TextInput
        source="marketingCode"
        label="Nazwa rabatu"
        validate={[required()]}
      />
      <SelectInput
        source="type"
        choices={DISCOUNT_TYPE}
        label="Typ rabatu"
        validate={[required()]}
      />
      <SelectInput
        source="currency"
        choices={CURRENCY}
        label="Waluta"
        validate={[required()]}
        initialValue={CURRENCY[0].name}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'amount' || formData.type === 'amount_per_hour' ? (
            <NumberInput
              source="amount"
              label={`Wartość w [${formData.currency || 'PLN'}]`}
              format={convertPriceToPLN}
              parse={convertPriceToGR}
              validate={[required(), minValue(1)]}
              min="1"
              {...rest}
            />
          ) : (
            <NumberInput
              source="amount"
              label="Wartość w procentach"
              validate={[required(), minValue(1)]}
              min="1"
              {...rest}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
        }
      </FormDataConsumer>
      <DateInput
        source="validTo"
        label="Ważny do"
        options={dateInputOptions}
        validate={[required()]}
        parse={(a) => {
          const foramtedDate = format(new Date(a), 'yyyy-MM-dd 23:59');
          return foramtedDate;
        }}
      />
      <ReferenceInput
        label="Użytkownik"
        source="userId"
        reference="users"
        link={false}
        validate={[required()]}
        perPage={999}
      >
        <AutocompleteInput
          optionText={(choice) => {
            if (choice) {
              if (
                choice.billingAddress &&
                choice.billingAddress.firstName &&
                choice.billingAddress.lastName
              ) {
                return `${choice.billingAddress.firstName} ${choice.billingAddress.lastName} (${choice.email}) `;
              }
              return `${choice.name || ''} (${choice.email}) `;
            }
            return null;
          }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default DiscountsCreate;
