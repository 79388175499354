import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNotify,
  Edit,
  SimpleForm,
  ReferenceField,
  TextField,
  required,
  Toolbar,
  TextInput,
  SaveButton,
  Button,
  fetchStart,
  fetchEnd,
} from 'react-admin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import { FormDataConsumer } from 'ra-core';
import { FormSpy } from 'react-final-form';
import NavigationPrompt from 'react-router-navigation-prompt';
import { DateTimeInput } from '../../components/DateInputs';
import CancelBookingButton from '../../layout/CancelBookingButton';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';
import { apiUrl } from '../../config/api';
import { ROLES_KEYS } from '../../config/constants';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const BookingsEditToolbar = withStyles(styles)(
  ({ dispatch, classes, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" {...props} />
      <CancelBookingButton label="Anuluj rezerwację" {...props} />
    </Toolbar>
  ),
);

const PriceField = ({ record = {} }) => (
  <span>
    {record.priceData && (record.priceData.price / 100).toFixed(2)}&nbsp;
    {record.priceData && record.priceData.currency}
  </span>
);

PriceField.defaultProps = { label: 'Cena Rezerwacji', addLabel: true };

const EmailField = ({ record = {} }) => (
  <span>{record.email && record.email}</span>
);

EmailField.defaultProps = { label: 'Email', addLabel: true };

const AddressField = ({ record = {} }) => (
  <span>
    {(record.billingAddress &&
      `${record.billingAddress.city || '-'},
        ${record.billingAddress.street || '-'}
        ${record.billingAddress.streetNumber || '-'},
        ${record.billingAddress.zipCode || '-'}`) ||
      (record.user &&
        record.user.billingAddress &&
        `${record.user.billingAddress.city || '-'},
        ${record.user.billingAddress.street || '-'}
        ${record.user.billingAddress.streetNumber || '-'},
        ${record.user.billingAddress.zipCode || '-'}`)}
  </span>
);
AddressField.defaultProps = { label: 'Adres', addLabel: true };

const NIPField = ({ record = {} }) => (
  <span>
    {(record.billingAddress && record.billingAddress.vatNumber) || '-'}
  </span>
);
NIPField.defaultProps = { label: 'NIP', addLabel: true };

const PhoneField = ({ record = {} }) => (
  <span>{(record.user && record.user.phone) || '-'}</span>
);
PhoneField.defaultProps = { label: 'Telefon', addLabel: true };

const NameField = ({ record = {} }) => {
  if (record.billingAddress) {
    return (
      <span>
        {(record.billingAddress &&
          `${record.billingAddress.firstName} ${record.billingAddress.lastName}`) ||
          record.billingAddress.name}
      </span>
    );
  }
  if (record.user) {
    return (
      <span>{record.user && record.user.name && `${record.user.name}`}</span>
    );
  }
  return '';
};
NameField.defaultProps = { label: 'Użytkownik', addLabel: true };

const dateTimeInputOptions = {
  ampm: false,
  keyboard: 'true',
  minutesStep: 30,
  mask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /[30]/,
    /0/,
  ],
  format: 'dd.MM.yyyy HH:mm:00',
  cancelLabel: 'Anuluj',
};

const minTimeRangeValidate = (value, allValues) => {
  const diff = new Date(value) - new Date(allValues.from);
  if (diff < 60 * 60 * 1000) {
    return 'Rezerwacja musi trwać przynajmniej 1 godzinę';
  }
  return undefined;
};

const toDateTimeValidate = [required(), minTimeRangeValidate];

const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

export const BookingsEdit = ({ permissions, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const notify = useNotify();
  const dispatch = useDispatch();

  const handleClick = async (orderId) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    try {
      fetch(`${apiUrl}/orders/${orderId}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ paymentState: 'PAID' }),
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.status === 'error') {
            notify(`Coś poszło nie tak. ${resp.message}`, 'warning');
          } else {
            notify('Rezerwacja została opłacona');
          }
        })
        .catch((err) => {
          notify(`Coś poszło nie tak. ${err.message}`, 'warning');
        });
    } catch (error) {
      console.log({ error });
      notify('Błąd: comment not approved', 'warning');
    } finally {
      setLoading(false);
      setIsPaid(true);
      dispatch(fetchEnd()); // stop the global loading indicator
    }
  };

  return (
    <Edit {...props} undoable={false} title="Rezerwacja">
      <SimpleForm
        submitOnEnter={false}
        toolbar={<BookingsEditToolbar />}
        redirect={() => {
          let rp = 'list';
          if (localStorage.getItem('ballroom:referer')) {
            rp = localStorage.getItem('ballroom:referer');
            localStorage.removeItem('ballroom:referer');
          }
          return rp;
        }}
      >
        <FormSpy
          subscription={{
            modified: true,
          }}
        >
          {(spyProps) => (
            <>
              <NavigationPrompt when={isAnyModified(spyProps.modified)}>
                {({ onConfirm, onCancel }) => (
                  <ConfirmNavigationModal
                    when
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                  />
                )}
              </NavigationPrompt>
            </>
          )}
        </FormSpy>
        <TextField source="id" label="Nr. rezerwacji" />
        <TextField source="shortCode" label="Skrócony nr. rezerwacji" />
        <ReferenceField
          label="Nazwa sali"
          source="_bookableObjectId"
          reference="inventory"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextInput label="Opis" source="description" />
        <DateTimeInput
          source="from"
          label="Od"
          options={dateTimeInputOptions}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <DateTimeInput
              source="to"
              label="Do"
              validate={toDateTimeValidate}
              options={{
                ...dateTimeInputOptions,
                minDate: formData.from,
                minutesStep: 30,
              }}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <TextField source="numberOfUsers" label="Ilość osób" />
        <PriceField source="bookings" />
        <br />
        <h3 style={{ margin: 0, fontSize: '18px', fontWeight: 'normal' }}>
          Dane billingowe
        </h3>
        <NameField source="bookings" />
        <EmailField source="bookings" />
        <AddressField source="bookings" />
        <NIPField source="bookings" />
        <PhoneField source="bookings" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.store === 'de' &&
            formData.paymentState === 'PROCESSING' &&
            permissions === ROLES_KEYS.ADMIN && (
              <>
                <Button
                  label={
                    isPaid
                      ? 'Rezerwacja została oznaczona jako opłacona'
                      : 'Oznacz jako opłacona'
                  }
                  onClick={() => handleClick(formData._orderId)}
                  disabled={loading || isPaid}
                >
                  <CheckCircleIcon />
                </Button>
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default BookingsEdit;
