export const timeParser = (v) => {
  let time = 0;
  if (v) {
    // eslint-disable-next-line no-cond-assign
    time = v.getHours() * 3600 + v.getMinutes() * 60;
    if (time === 0) time = 86400;
  }
  return time;
};

export const timeParserFrom = (v) => {
  let time = 0;
  if (v) {
    // eslint-disable-next-line no-cond-assign
    time = v.getHours() * 3600 + v.getMinutes() * 60;
    if (time === 86400) time = 0;
  }
  return time;
};

const doublePlacesFormatted = (v) => (v.toString().length === 1 ? `0${v}` : v);

export const getHourFromSeconds = (seconds) => {
  const date = new Date(
    0,
    0,
    0,
    Math.floor(seconds / 3600),
    Math.floor((seconds % 3600) / 60),
  );
  return `${doublePlacesFormatted(date.getHours())}:${doublePlacesFormatted(
    date.getMinutes(),
  )}`;
};
