export const DAYS = {
  1: 'Poniedziałek',
  2: 'Wtorek',
  3: 'Środa',
  4: 'Czwartek',
  5: 'Piątek',
  6: 'Sobota',
  7: 'Niedziela',
};

export const COUNTRIES = {
  pl: 'Polska',
  de: 'Niemcy',
  fr: 'Francja',
};
