/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { List, withDataProvider } from 'react-admin';
import isEqual from 'lodash/isEqual';
import { firstDayOfWeek, lastDayOfWeek } from './utils/matrixHelpers';
import MatrixFilters from './components/MatrixFilters';
import MatrixGrid from './components/MatrixGrid';
import { isAdmin } from '../../utils/roles';

const Empty = () => null;

class MatrixList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressId: null,
      initialized: false,
    };
  }

  componentDidMount = async () => {
    const { dataProvider } = this.props;

    const { data: locations } = await dataProvider.getList('addresses', {
      pagination: { page: 1, perPage: 30 },
      sort: { field: 'city', order: 'desc' },
      filter: { active: true },
    });

    if (isAdmin) {
      const defaultLocation = locations.find(
        (loc) =>
          loc.city.includes('Warszawa') && loc.street.includes('Jerozolimskie'),
      );
      if (defaultLocation) {
        this.setState({ addressId: defaultLocation._id });
      }
    }
    this.setState({ initialized: true });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    const { dataProvider, dispatch, ...props } = this.props;
    const { addressId, initialized } = this.state;

    const filterDefaultValues = {
      from: firstDayOfWeek(),
      to: lastDayOfWeek(),
    };

    if (addressId) {
      filterDefaultValues.addressId = addressId;
    }

    if (!initialized) {
      return null;
    }

    return (
      <List
        {...props}
        title="Grafik"
        filterDefaultValues={filterDefaultValues}
        filters={<MatrixFilters />}
        pagination={<Empty />}
        exporter={false}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <MatrixGrid location={props.history.location} />
      </List>
    );
  }
}

export default withDataProvider(MatrixList);
