import React from 'react';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { Button, SaveButton } from 'react-admin';
import { useForm } from 'react-final-form';

export const ConfirmNavigationModal = (props) => {
  const { when, onCancel, onConfirm } = props;
  const form = useForm();
  const handleClick = async () => {
    await form.submit();
    onConfirm();
  };
  return (
    <>
      <Dialog
        fullWidth
        open={when}
        onClose={onCancel}
        aria-label="Are you sure?"
      >
        <DialogTitle>
          Zostały wprowadzone zmiany. Czy chcesz kontynuować?
        </DialogTitle>
        <DialogActions>
          <SaveButton label="Zapisz" handleSubmitWithRedirect={handleClick} />
          <Button label="Kontynuuj" onClick={onConfirm}>
            <IconCancel />
          </Button>
          <Button label="Anuluj" onClick={onCancel}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmNavigationModal;
