import React from 'react';
import {
  Create,
  required,
  SimpleForm,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  minValue,
} from 'react-admin';
import filter from 'lodash/filter';
import { withStyles } from '@material-ui/core/styles';
import { TimeInput } from '../../components/DateInputs';
import {
  convertPriceToPLN,
  convertPriceToGR,
} from '../../utils/priceConverter';
import {
  DISCOUNT_TYPE,
  CURRENCY,
  COUNTRIES,
  COUNTRY_KEYS,
} from '../../config/constants';
import styles from '../../components/FormStyles';
import { timeParserFrom, timeParser } from '../../utils/time';
import { validateTimeBy30 } from '../../utils/customValidators';

const discountTypes = filter(DISCOUNT_TYPE, (dt) => dt.id !== 'amount');

const timeRangeValidate = [required(), validateTimeBy30];

const PromotionsCreate = withStyles(styles)(({ classes, ...props }) => (
  <Create {...props} title="Dodawanie promocji">
    <SimpleForm submitOnEnter={false} redirect={(basePath) => basePath}>
      <TextInput
        source="name"
        label="Nazwa promocji"
        validate={[required()]}
        className={classes.input}
      />
      <SelectInput
        source="type"
        choices={discountTypes}
        label="Typ promocji"
        validate={[required()]}
        className={classes.input}
      />
      <TextInput
        source="description"
        label="Opis"
        validate={[required()]}
        className={classes.input}
        multiline
      />
      <SelectInput
        source="store"
        choices={Object.keys(COUNTRIES)
          .filter((c) => c !== COUNTRY_KEYS.FRANCE)
          .map((c) => ({
            id: c,
            name: COUNTRIES[c],
          }))}
        label="Lokalizacja sali"
        validate={[required()]}
        defaultValue={COUNTRY_KEYS.POLAND}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SelectInput
            source="currency"
            choices={CURRENCY}
            label="Waluta"
            validate={[required()]}
            initialValue={
              formData.store === COUNTRY_KEYS.POLAND ? 'PLN' : 'EUR'
            }
            {...rest}
          />
        )
        // eslint-disable-next-line react/jsx-curly-newline
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'amount' || formData.type === 'amount_per_hour' ? (
            <>
              <NumberInput
                source="amount"
                label={`Wartość w [${formData.currency}]`}
                format={convertPriceToPLN}
                parse={convertPriceToGR}
                validate={[required(), minValue(1)]}
                min="1"
                {...rest}
                className={classes.input}
              />
            </>
          ) : (
            <NumberInput
              source="amount"
              label="Wartość w procentach"
              validate={[required(), minValue(1)]}
              min="1"
              {...rest}
              className={classes.input}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <ArrayInput
            source="pricings"
            label="Detale promocji"
            validate={[required()]}
            className={classes.elInRow}
          >
            <SimpleFormIterator>
              <NumberInput
                min="1"
                source="validity_in_days"
                label="Ważny przez (dni)"
                validate={[required(), minValue(1)]}
                className={classes.input}
              />
              <NumberInput
                min="1"
                source="price"
                label={`Cena w ${formData.currency}`}
                validate={[required(), minValue(1)]}
                format={convertPriceToPLN}
                parse={convertPriceToGR}
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormDataConsumer>
      <ArrayInput
        source="availability"
        label="Dostępność"
        validate={[required()]}
        className={classes.elInRow}
      >
        <SimpleFormIterator>
          <SelectInput
            fullWidth
            source="day"
            label="Dzień"
            validate={[required()]}
            choices={[
              { id: 1, name: 'Poniedziałek' },
              { id: 2, name: 'Wtorek' },
              { id: 3, name: 'Środa' },
              { id: 4, name: 'Czwartek' },
              { id: 5, name: 'Piątek' },
              { id: 6, name: 'Sobota' },
              { id: 7, name: 'Niedziela' },
            ]}
          />
          <TimeInput
            fullWidth
            source="fromSecond"
            label="Od"
            validate={[required()]}
            options={{ format: 'HH:mm', ampm: false }}
            format={(record) => {
              if (record !== undefined && record !== null) {
                return new Date(
                  0,
                  0,
                  0,
                  Math.floor(record / 3600),
                  Math.floor((record % 3600) / 60),
                );
              }

              return null;
            }}
            parse={timeParserFrom}
          />
          <TimeInput
            fullWidth
            source="toSecond"
            label="Do"
            validate={[required()]}
            options={{ format: 'HH:mm', ampm: false }}
            format={(record) => {
              if (record) {
                return new Date(
                  0,
                  0,
                  0,
                  Math.floor(record / 3600),
                  Math.floor((record % 3600) / 60),
                );
              }
              return null;
            }}
            parse={timeParser}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput
        label="Ekskluzywna promocja"
        source="is_exclusive"
        defaultValue={false}
      />
      <NumberInput
        min="1"
        source="max_minutes_per_day"
        label="Minuty do wykorzystania danej promocji na dzień"
        validate={timeRangeValidate}
        fullWidth
      />
    </SimpleForm>
  </Create>
));

export default PromotionsCreate;
