import React from 'react';
import {
  Create,
  Edit,
  required,
  regex,
  minLength,
  email,
  SimpleForm,
  TextInput,
  SelectInput,
  Button,
  Toolbar,
  SaveButton,
  RadioButtonGroupInput,
  FormDataConsumer,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { FormSpy } from 'react-final-form';
import NavigationPrompt from 'react-router-navigation-prompt';
import moment from 'moment';
import DeleteButtonWithConfirmation from '../../layout/DeleteButtonWithConfirmation';
import { USER_ROLES } from '../../config/constants';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';
import { DateTimeInput } from '../../components/DateInputs';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));

const validatePhone = regex(
  /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/,
  'Proszę wprowadzić poprawny numer telefonu',
);

const countryList = countries.getNames('pl', { select: 'official' });

const COUNTRY_SELECT_OPTIONS = Object.keys(countryList).map((key) => ({
  id: key.toLowerCase(),
  name: countryList[key],
}));

const dateTimeInputOptions = {
  ampm: false,
  keyboard: 'true',
  minutesStep: 1,
  // mask: [
  //   /\d/,
  //   /\d/,
  //   '.',
  //   /\d/,
  //   /\d/,
  //   '.',
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   ' ',
  //   /\d/,
  //   /\d/,
  //   ':',
  //   /[30]/,
  //   /0/,
  // ],
  // format: 'dd.MM.yyyy HH:mm:00',
  cancelLabel: 'Anuluj',
};

const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

const EditionCancelModal = () => (
  <FormSpy
    subscription={{
      modified: true,
    }}
  >
    {(spyProps) => (
      <>
        <NavigationPrompt when={isAnyModified(spyProps.modified)}>
          {({ onConfirm, onCancel }) => (
            <ConfirmNavigationModal
              when
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </NavigationPrompt>
      </>
    )}
  </FormSpy>
);

const BillingForm = ({
  setShowBillingFields,
  showBillingFields,
  ...props // eslint-disable-line
}) => (
  <div
    style={{
      margin: '20px -16px',
      padding: '16px',
      backgroundColor: 'rgba(0,0,0,.02)',
    }}
  >
    <Button
      type="button"
      onClick={() => setShowBillingFields(!showBillingFields)}
      label={`${
        !showBillingFields ? 'Dodaj dane bilingowe' : 'Ukryj dane bilingowe'
      }`}
    >
      <AddIcon />
    </Button>

    {showBillingFields && (
      <div>
        <RadioButtonGroupInput
          source="billingAddress.type"
          label="Typ konta"
          required
          defaultValue="private"
          choices={[
            { id: 'private', name: 'prywatne' },
            { id: 'company', name: 'firmowe' },
          ]}
        />
        <TextInput
          label="Imię"
          source="billingAddress.firstName"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Nazwisko"
          source="billingAddress.lastName"
          validate={[required()]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress &&
                formData.billingAddress.type === 'company' && (
                  <TextInput
                    label="Nazwa firmy"
                    source="billingAddress.companyName"
                    validate={
                      formData.billingAddress.type === 'company' && [required()]
                    }
                    fullWidth
                  />
                )}
            </>
          )}
        </FormDataConsumer>

        <SelectInput
          source="billingAddress.country"
          choices={COUNTRY_SELECT_OPTIONS}
          label="Kraj"
          validate={[required()]}
          defaultValue="pl"
          fullWidth
        />
        <TextInput
          label="Miasto"
          source="billingAddress.city"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Ulica"
          source="billingAddress.street"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Numer ulicy"
          source="billingAddress.streetNumber"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Numer mieszkania"
          source="billingAddress.flatNumber"
          fullWidth
        />
        <TextInput
          label="Kod pocztowy"
          source="billingAddress.zipCode"
          validate={[required()]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress &&
                formData.billingAddress.type === 'company' && (
                  <RadioButtonGroupInput
                    source="billingAddress.businessType"
                    label="Typ firmy"
                    required
                    defaultValue="self_employed"
                    choices={[
                      {
                        id: 'self_employed',
                        name: 'Jednoosobowa działalność gospodarcza',
                      },
                      { id: 'otherBusiness', name: 'Inny rodzaj działalności' },
                    ]}
                  />
                )}
            </>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress &&
                formData.billingAddress.type === 'company' && (
                  <TextInput
                    label="NIP"
                    source="billingAddress.vatNumber"
                    validate={
                      formData.billingAddress.type === 'company' && [required()]
                    }
                    fullWidth
                  />
                )}
            </>
          )}
        </FormDataConsumer>
      </div>
    )}
  </div>
);

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const FormToolbar = withStyles(styles)(
  ({ dispatch, classes, kind, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" {...props} />
      {kind === 'edit' && <DeleteButtonWithConfirmation {...props} />}
    </Toolbar>
  ),
);

const BillingFields = ({ record = {} }) => {
  const { billingAddress } = record;

  if (billingAddress) {
    // eslint-disable-next-line no-unused-expressions
    return (
      <div
        style={{
          margin: '20px -16px',
          padding: '16px',
          backgroundColor: 'rgba(0,0,0,.02)',
        }}
      >
        <h3 style={{ margin: 0, fontSize: '18px', fontWeight: 'normal' }}>
          Dane billingowe
        </h3>
        <RadioButtonGroupInput
          source="billingAddress.type"
          label="Typ konta"
          required
          defaultValue="private"
          choices={[
            { id: 'private', name: 'prywatne' },
            { id: 'company', name: 'firmowe' },
          ]}
        />
        <TextInput
          label="Imię"
          source="billingAddress.firstName"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Nazwisko"
          source="billingAddress.lastName"
          validate={[required()]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress &&
                formData.billingAddress.type === 'company' && (
                  <TextInput
                    label="Nazwa firmy"
                    source="billingAddress.companyName"
                    validate={
                      formData.billingAddress.type === 'company' && [required()]
                    }
                    fullWidth
                  />
                )}
            </>
          )}
        </FormDataConsumer>
        <SelectInput
          source="billingAddress.country"
          choices={COUNTRY_SELECT_OPTIONS}
          label="Kraj"
          validate={[required()]}
          defaultValue="pl"
          fullWidth
        />
        <TextInput
          label="Miasto"
          source="billingAddress.city"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Ulica"
          source="billingAddress.street"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Numer ulicy"
          source="billingAddress.streetNumber"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label="Numer mieszkania"
          source="billingAddress.flatNumber"
          fullWidth
        />
        <TextInput
          label="Kod pocztowy"
          source="billingAddress.zipCode"
          validate={[required()]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress &&
                formData.billingAddress.type === 'company' && (
                  <RadioButtonGroupInput
                    source="billingAddress.businessType"
                    label="Typ firmy"
                    required
                    initialValue="self_employed"
                    choices={[
                      {
                        id: 'self_employed',
                        name: 'Jednoosobowa działalność gospodarcza',
                      },
                      { id: 'otherBusiness', name: 'Inny rodzaj działalności' },
                    ]}
                  />
                )}
            </>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.billingAddress &&
                formData.billingAddress.type === 'company' && (
                  <TextInput
                    label="NIP"
                    source="billingAddress.vatNumber"
                    validate={
                      formData.billingAddress.type === 'company' && [required()]
                    }
                    fullWidth
                  />
                )}
            </>
          )}
        </FormDataConsumer>
      </div>
    );
  }
  return (
    <div
      style={{
        margin: '20px -16px',
        padding: '16px',
        backgroundColor: 'rgba(0,0,0,.02)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h3 style={{ margin: 0, fontSize: '18px', fontWeight: 'normal' }}>
        Dane billingowe
      </h3>
      <RadioButtonGroupInput
        source="billingAddress.type"
        label="Typ konta"
        required
        defaultValue="private"
        choices={[
          {
            id: 'private',
            name: 'Osoba prywatna',
          },
          { id: 'company', name: 'Firma' },
        ]}
      />
      <TextInput
        label="Imię"
        source="billingAddress.firstName"
        validate={[required()]}
        initialValue=""
      />
      <TextInput
        label="Nazwisko"
        source="billingAddress.lastName"
        validate={[required()]}
        initialValue=""
      />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.billingAddress &&
              formData.billingAddress.type === 'company' && (
                <TextInput
                  label="Nazwa firmy"
                  source="billingAddress.companyName"
                  validate={
                    formData.billingAddress.type === 'company' && [required()]
                  }
                  initialValue=""
                />
              )}
          </>
        )}
      </FormDataConsumer>

      <SelectInput
        source="billingAddress.country"
        choices={COUNTRY_SELECT_OPTIONS}
        label="Kraj"
        validate={[required()]}
        initialValue="pl"
      />
      <TextInput
        label="Miasto"
        source="billingAddress.city"
        validate={[required()]}
        initialValue=""
      />
      <TextInput
        label="Ulica"
        source="billingAddress.street"
        validate={[required()]}
        initialValue=""
      />
      <TextInput
        label="Numer ulicy"
        source="billingAddress.streetNumber"
        validate={[required()]}
        initialValue=""
      />
      <TextInput label="Numer mieszkania" source="billingAddress.flatNumber" />
      <TextInput
        label="Kod pocztowy"
        source="billingAddress.zipCode"
        validate={[required()]}
        initialValue=""
      />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.billingAddress &&
              formData.billingAddress.type === 'company' && (
                <RadioButtonGroupInput
                  source="billingAddress.businessType"
                  label="Typ firmy"
                  required
                  initialValue="self_employed"
                  choices={[
                    {
                      id: 'self_employed',
                      name: 'Jednoosobowa działalność gospodarcza',
                    },
                    { id: 'otherBusiness', name: 'Inny rodzaj działalności' },
                  ]}
                />
              )}
          </>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData.billingAddress &&
              formData.billingAddress.type === 'company' && (
                <TextInput
                  label="NIP"
                  validate={
                    formData.billingAddress.type === 'company' && [required()]
                  }
                  initialValue=""
                  source="billingAddress.vatNumber"
                />
              )}
          </>
        )}
      </FormDataConsumer>
    </div>
  );
};

const PremiumSection = ({ record = {} }) => (
  <div
    style={{
      margin: '20px -16px',
      padding: '16px',
      backgroundColor: 'rgba(0,0,0,.02)',
    }}
  >
    <h3 style={{ margin: 0, fontSize: '18px' }}>PREMIUM</h3>

    <div style={{ margin: '20px 0' }}>
      {record.isPremium
        ? `Użytkownik ma wykupione konto premium do dnia ${moment(
            record.premiumTo,
          ).format('DD.MM.YYYY hh:mm')}`
        : 'Użytkownik nie ma konta premium'}
    </div>

    <div>
      <h4 style={{ display: 'block', marginBottom: 20 }}>Dodatkowe rabaty</h4>

      {record.promotions && record.promotions.length > 0 ? (
        <ul style={{ margin: 0, padding: 0 }}>
          {record.promotions.map((p, i) => (
            <li
              key={p._id}
              style={{
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: 10,
                borderTop: i !== 0 ? '1px rgba(0,0,0, 0.1) solid' : 0,
                paddingTop: i !== 0 ? 10 : 0,
              }}
            >
              <h5
                style={{
                  fontSize: 14,
                  textTransform: 'uppercase',
                  margin: '0 15px 0 0',
                }}
              >
                {p.name || 'nazwa promocji'}
              </h5>
              <time style={{ fontSize: 14, marginRight: 15 }}>
                Data zakupu:{' '}
                {p.boughtAt
                  ? moment(p.boughtAt).format('DD.MM.YYYY HH:mm')
                  : 'brak'}
              </time>
              <time style={{ fontSize: 14, marginRight: 15 }}>
                Wygasa: {moment(p.validTo).format('DD.MM.YYYY HH:mm')}
              </time>
            </li>
          ))}
        </ul>
      ) : (
        'Nie ma wykupionych dodatkowych promocji.'
      )}
    </div>
  </div>
);

const Form = ({ kind, ...props }) => {
  const [showBillingFields, setShowBillingFields] = React.useState(false);
  const WrapperComponent = kind === 'edit' ? Edit : Create;

  if (kind === 'edit') {
    props.undoable = false;
  }

  return (
    <WrapperComponent
      {...props}
      title={kind === 'edit' ? 'Użytkownik' : 'Tworzenie nowego użytkownika'}
    >
      <SimpleForm
        submitOnEnter={false}
        redirect={(basePath) => basePath}
        toolbar={<FormToolbar kind={kind} />}
      >
        {kind === 'edit' && <EditionCancelModal />}
        <TextInput
          label="Nazwa użytkownika"
          source="name"
          validate={[required()]}
          fullWidth
        />
        <TextInput label="Nazwa na Facebooku" source="fbUsername" fullWidth />
        <TextInput
          label="Email"
          source="email"
          type="email"
          validate={[required(), email()]}
          fullWidth
        />
        <TextInput
          label="Numer telefonu"
          source="phone"
          validate={[validatePhone]}
          fullWidth
        />

        {kind === 'create' && (
          <BillingForm
            setShowBillingFields={setShowBillingFields}
            showBillingFields={showBillingFields}
          />
        )}
        {kind === 'edit' && <PremiumSection />}
        {kind === 'edit' && <BillingFields />}

        <SelectInput
          source="role"
          choices={USER_ROLES}
          label="Rola"
          validate={[required()]}
          defaultValue="USER"
          fullWidth
        />
        <TextInput
          label={kind === 'create' ? 'Hasło' : 'Nowe hasło'}
          source="password"
          validate={kind === 'create' && [required(), minLength(8)]}
          type="password"
          autocomplete="off"
          fullWidth
        />

        <DateTimeInput
          source="premiumTo"
          label="Użytkownik premium do dnia"
          options={dateTimeInputOptions}
        />
      </SimpleForm>
    </WrapperComponent>
  );
};

export default Form;
