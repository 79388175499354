/* jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  useNotify,
  Button,
  SaveButton,
  useCreate,
  useRefresh,
} from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Form from '../../resources/Bookings/BookingForm';

require('./Mui.css');

const MySaveButton = ({ record, disabled }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [approve, { loading }] = useCreate('bookings', record, {
    onSuccess: () => {
      refresh();
      notify('Rezerwacja została dodana');
    },
    onFailure: (error) =>
      notify(`Comment approval error: ${error.message}`, 'warning'),
  });

  return (
    <SaveButton
      label="Dodaj rezerwację"
      handleSubmitWithRedirect={approve}
      disabled={disabled || loading}
    />
  );
};

export const BookingModal = ({ room, day, time, onCloseHandler }) => {
  const handleClose = () => {
    onCloseHandler();
  };
  const [formState, setFormState] = useState(null);

  return (
    <Dialog fullWidth open onClose={handleClose} aria-label="Are you sure?">
      <DialogTitle>Nowa rezerwacja</DialogTitle>
      <DialogContent>
        <Form
          className="booking-modal"
          toolbar={null}
          basePath="/matrix"
          resource="bookings"
          setFormState={setFormState}
          room={room}
          day={day}
          time={time}
        />
      </DialogContent>
      <DialogActions>
        <MySaveButton
          record={formState ? formState.values : null}
          disabled={!formState || !formState.valid}
        />
        <Button label="Anuluj" onClick={handleClose}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BookingModal;
