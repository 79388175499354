import React from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Global
    styles={css`
      html {
        box-sizing: border-box;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .layout {
        > div {
          margin-top: 68px;
          min-height: calc(100vh - 68px);
        }
      }

      #main-content {
        @media screen and (min-width: 560px) {
          padding-left: 24px;
        }
      }

      .Layout-contentWithSidebar-3 {
        z-index: 1;
      }

      .MuiDrawer-paper {
        > div {
          width: 100%;
        }
      }

      .MuiMenuItem-root {
        text-transform: uppercase;
        padding: 10px 15px;
        font-size: 14px;
        /* margin: 0 5px; */
        /* border-radius: 5px; */
      }

      .RaMenuItemLink-active-154 {
        background-color: rgba(0, 0, 0, 0.08);
        /* background-color: rgba(217, 162, 72, 0.7); */
      }

      .MuiAppBar-colorSecondary {
        background-color: #192025;
        padding: 10px 0;
      }

      .MuiDrawer-docked {
        /* margin-right: 5px; */
        background: #ededed;
      }

      .Layout-content-4 {
        width: calc(100vw - 355px);
      }

      .MuiToolbar-regular {
        min-height: unset;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .MuiToolbar-root {
        &::not('.MuiPickersToolbar-toolbar') {
          background-color: transparent;
        }
        &.MuiPickersToolbar-toolbar {
          margin-bottom: 0;
        }
      }

      .MuiTableHead-root {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .MuiTableCell-head {
        font-weight: 700;
        white-space: nowrap;
      }

      .MuiCard-root {
        overflow: auto;
      }

      .column-pricing,
      .column-availability {
        padding: 0;
        vertical-align: top;

        .MuiTableCell-head {
          font-size: 12px;
          font-weight: 300;
        }

        .column-pricing {
          padding: 6px 24px 6px 16px;
        }

        .MuiTableBody-root {
          .MuiTableRow-root {
            &:last-child {
              .MuiTableCell-root {
                border-bottom: 0;
              }
            }
          }
        }
      }

      .MuiTablePagination-root {
        display: block;
        background: rgba(0, 0, 0, 0.03);

        .MuiToolbar-root {
          margin-bottom: 0;
          padding: 5px;
          font-size: 12px;

          .MuiTypography-body2 {
            font-size: 12px;
            font-weight: 300;
          }
        }
      }

      .sidebar {
        background: #ededed;
        width: 100%;
        height: 100%;

        .MuiListItemIcon-root {
          img {
            transition: all 0.3s ease-in-out;
          }
        }

        &--collapsed {
          .MuiMenuItem-root {
            margin: 0;
          }

          .MuiListItemIcon-root {
            img {
              width: 32px;
              height: 32px;
              min-width: unset;
            }
          }
        }
      }

      .menu-item {
        transition: all 0.3s ease-in-out;

        &--active {
          background-color: rgba(0, 0, 0, 0.08);
        }
      }

      .ql-editor {
        min-height: 300px;
      }

      .overflow-visible {
        .MuiPaper-root {
          overflow: visible;
        }
      }
    `}
  />
);
