/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { useRedirect } from 'react-admin';
import isEqual from 'lodash/isEqual';

import { isAvailable } from '../utils/matrixHelpers';
import { getStylesForBooking, getStylesForCell } from '../utils/lighterColor';
// import { isAdmin } from '../../utils/roles';

const timeFormatOptions = { hour: '2-digit', minute: '2-digit' };

require('./Mui.css');

const Cell = ({
  day,
  time,
  booking,
  isAvailableCell,
  className,
  room,
  openBookingModal,
  openCloneModal,
  location,
}) => {
  const redirect = useRedirect();

  const cellClickHandler = () => {
    if (booking._id) {
      localStorage.setItem(
        'ballroom:referer',
        `${location.pathname}${location.search}`,
      );
      redirect(`/bookings/${booking._id}`);
      return null;
    }
    if (isAvailableCell && !booking._id) {
      openBookingModal(room, day, time);
    }
    return null;
  };

  const fromDate = new Date(booking.from);
  const toDate = new Date(booking.to);

  const priceInPLN = (price) => {
    return (price / 100).toFixed(2);
  };
  // const cell = useRef();

  let rowspan = 1;
  if (booking._id) {
    rowspan = (toDate.valueOf() - fromDate.valueOf()) / (30 * 60 * 1000);
  }

  return (
    <TableCell
      className={className}
      style={{
        cursor: isAvailableCell ? 'pointer' : 'not-allowed',
        borderRight: '1px rgba(0,0,0, .08) solid',
        ...getStylesForCell(day, isAvailableCell),
      }}
      onClick={cellClickHandler}
      rowSpan={rowspan}
    >
      {booking._id && (
        <div
          className="booking-cell"
          style={{
            position: 'relative',
            lineHeight: '18px',
            padding: 10,
            borderRadius: '3px',
            boxShadow: '2px 2px 2px rgba(0,0,0, .1)',
            backgroundColor: booking._id && '#b9d6ce',
            height: booking._id
              ? booking.shortCode ||
                (booking.priceData && booking.priceData.price)
                ? rowspan * 45
                : rowspan * 45 - 17
              : 'auto',
            ...getStylesForBooking(booking),
          }}
        >
          <button
            type="button"
            className="clone-button"
            onClick={(ev) => {
              ev.stopPropagation();
              openCloneModal(booking);
            }}
          >
            klonuj
          </button>

          <b>
            {booking.description ||
              booking.name ||
              (booking.billingAddress && booking.billingAddress.name)}
          </b>
          <span style={{ display: 'block', fontSize: '11px' }}>
            {fromDate.toLocaleTimeString('pl-PL', timeFormatOptions)} -{' '}
            {toDate.toLocaleTimeString('pl-PL', timeFormatOptions)}
          </span>
          <span style={{ display: 'block', fontSize: '11px' }}>
            {booking.shortCode}
          </span>
          {booking.priceData && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '11px',
                fontWeight: 'bold',
                marginTop: 3,
              }}
            >
              {booking.paymentState === 'PROCESSING' ? (
                <img
                  src="/images/stopwatch.svg"
                  alt="oczekiwanie na płatność"
                  style={{
                    display: 'block',
                    width: 14,
                    marginRight: 5,
                    position: 'relative',
                    top: -2,
                  }}
                />
              ) : null}
              {priceInPLN(booking.priceData.price)}&nbsp;
              {booking.priceData.currency || 'PLN'}
            </span>
          )}
        </div>
      )}
    </TableCell>
  );
};

const lastIdInDay = {};

class BookingCell extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      day,
      time,
      className,
      dayIndex,
      room,
      openBookingModal,
      openCloneModal,
      location,
    } = this.props;

    const booking =
      day.bookings.find((b) => {
        return b.fromSeconds <= time.time && b.toSeconds > time.time;
      }) || {};

    const isAvailableCell = isAvailable(day, time);

    if (booking._id && lastIdInDay[dayIndex] === booking._id) {
      return <></>;
    }
    lastIdInDay[dayIndex] = booking._id;

    return (
      <Cell
        day={day}
        time={time}
        booking={booking}
        isAvailableCell={isAvailableCell}
        className={className}
        room={room}
        openBookingModal={openBookingModal}
        openCloneModal={openCloneModal}
        location={location}
      />
    );
  }
}

export default BookingCell;
