import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, alpha } from '@material-ui/core/styles';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { Button } from 'react-admin';

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  message: {
    fontFamily: theme.typography.fontFamily,
  },
});

class DeleteButtonWithConfirmation extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    showDialog: false,
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    const {
      dispatchCrudDelete,
      resource,
      record,
      basePath,
      redirect,
      startUndoable: startUndoableInner,
      selectedIds,
      undoable,
    } = this.props;
    if (selectedIds) {
      if (undoable) {
        selectedIds.map((id) =>
          startUndoableInner(
            crudDelete(resource, id, record, basePath, redirect),
          ),
        );
      } else {
        selectedIds.map((id) =>
          dispatchCrudDelete(resource, id, record, basePath, redirect),
        );
      }
    } else if (record) {
      if (undoable) {
        startUndoableInner(
          crudDelete(resource, record.id, record, basePath, redirect),
        );
      } else {
        dispatchCrudDelete(resource, record.id, record, basePath, redirect);
      }
    }
  };

  render() {
    const { showDialog } = this.state;
    const { label = 'Usuń', classes = {}, className } = this.props;
    return (
      <>
        <Button
          onClick={this.handleClick}
          label={label}
          className={classnames(
            'ra-delete-button',
            classes.deleteButton,
            className,
          )}
          key="button"
        >
          <ActionDelete />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Are you sure?"
        >
          <DialogTitle>Czy na pewno chcesz usunąć element?</DialogTitle>
          <DialogActions>
            <Button
              onClick={this.handleDelete}
              label={label}
              className={classnames(
                'ra-delete-button',
                classes.deleteButton,
                className,
              )}
              key="button"
            >
              <ActionDelete />
            </Button>
            <Button label="Anuluj" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

DeleteButtonWithConfirmation.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  selectedIds: PropTypes.array,
  resource: PropTypes.string.isRequired,
  undoable: PropTypes.bool,
};

DeleteButtonWithConfirmation.defaultProps = {
  redirect: 'list',
  undoable: true,
};

export default compose(
  connect(null, { startUndoable, dispatchCrudDelete: crudDelete }),
  translate,
  withStyles(styles),
)(DeleteButtonWithConfirmation);
