import React, { useState } from 'react';
import {
  AutocompleteInput,
  Button,
  Create,
  Edit,
  required,
  maxValue,
  SimpleForm,
  TextInput,
  Toolbar,
  DateInput,
  SaveButton,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceField,
  NumberInput,
  ArrayInput,
  ImageInput,
  SelectInput,
  BooleanInput,
  TextField,
  TabbedShowLayout,
  Tab,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  minValue,
} from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import { format } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import NavigationPrompt from 'react-router-navigation-prompt';
import { FormSpy } from 'react-final-form';
import { ColorInput } from '../../components/ColorInput/colorInput';
import { TimeInput } from '../../components/DateInputs';
import DeleteButtonWithConfirmation from '../../layout/DeleteButtonWithConfirmation';
import {
  LANGUAGES,
  ROLES_KEYS,
  COUNTRY_KEYS,
  COUNTRIES,
  CURRENCY,
} from '../../config/constants';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';
import { checkDayAvailability } from '../Adresses/AddressForm';

import { timeParserFrom, timeParser } from '../../utils/time';
import {
  convertPriceToPLN,
  convertPriceToGR,
} from '../../utils/priceConverter';
import MainImageFieldOptions from './components/MainImageFieldOptions';
import ImageFromApiField from './components/ImageFromApiField';
import styles from '../../components/FormStyles';

const FormToolbar = withStyles(styles)(
  ({ classes, dispatch, kind, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" {...props} />
      {kind === 'edit' && <DeleteButtonWithConfirmation {...props} />}
    </Toolbar>
  ),
);

const FullAdressField = ({ record = {} }) => (
  <span>
    {record.city}, {record.street}
  </span>
);

FullAdressField.defaultProps = { label: 'Adress' };

const CountryField = ({ record = {} }) => (
  <span>{record.store && COUNTRIES[record.store]}</span>
);

CountryField.defaultProps = { label: 'Lokalizacja sali', addLabel: true };

const CurrencyField = ({ record = {} }) => (
  <span>{record.currency && record.currency}</span>
);

CurrencyField.defaultProps = { label: 'Waluta', addLabel: true };

// const RoomViewerField = ({ record }) => {
//   console.log({ record });
//   return <span>dupa</span>;
// };

const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

const EditionCancelModal = () => (
  <FormSpy
    subscription={{
      modified: true,
    }}
  >
    {(spyProps) => (
      <>
        <NavigationPrompt when={isAnyModified(spyProps.modified)}>
          {({ onConfirm, onCancel }) => (
            <ConfirmNavigationModal
              when
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </NavigationPrompt>
      </>
    )}
  </FormSpy>
);

export const RoomForm = withStyles(styles)(
  ({ classes, images, kind, isAddressSet, permissions, ...props }) => {
    const [showCustomAddress, setShowCustomAddress] = useState(false);
    const WrapperComponent = kind === 'edit' ? Edit : Create;

    if (kind === 'edit') {
      props.undoable = false;
    }

    const renderAddressField = () => {
      if (kind === 'edit') {
        return (
          <ReferenceField
            label="Adres"
            source="_addressId"
            reference="addresses"
            link={false}
            perPage={999}
          >
            <FullAdressField source="addresses" />
          </ReferenceField>
        );
      }

      return (
        <>
          {!showCustomAddress && (
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <ReferenceInput
                  label="Adres"
                  source="addressId"
                  reference="addresses"
                  link={false}
                  allowEmpty
                  validate={[required()]}
                  fullWidth
                  perPage={999}
                  filter={{ country: formData.store }}
                  {...rest}
                >
                  <AutocompleteInput
                    optionText={(choice) => {
                      if (!choice) return '';
                      if (!choice.city) return '';
                      if (!choice.country) return '';
                      return `${choice.city}, ${choice.street}`;
                    }}
                  />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          )}
          {!isAddressSet && (
            <>
              <Button
                type="button"
                onClick={() => setShowCustomAddress(!showCustomAddress)}
                label={`${
                  !showCustomAddress
                    ? 'Dodaj nowy adres'
                    : 'Pokaż listę adresów'
                }`}
              >
                <AddIcon />
              </Button>

              {showCustomAddress && (
                <div>
                  <FormDataConsumer>
                    {({ formData: { store } }) => (
                      <SelectInput
                        disabled
                        source="newAddress.country"
                        choices={Object.keys(COUNTRIES)
                          .filter((c) => c !== COUNTRY_KEYS.FRANCE)
                          .map((c) => ({
                            id: c,
                            name: COUNTRIES[c],
                          }))}
                        style={{ marginRight: '15px' }}
                        label="Kraj"
                        validate={[required()]}
                        initialValue={store}
                      />
                    )}
                  </FormDataConsumer>
                  <TextInput
                    label="Miasto"
                    source="newAddress.city"
                    validate={[required()]}
                    style={{ marginRight: '15px' }}
                  />
                  <TextInput
                    label="Ulica"
                    source="newAddress.street"
                    validate={[required()]}
                  />
                  <BooleanInput
                    label="Aktywny"
                    source="newAddress.active"
                    defaultValue={false}
                  />
                  <FormDataConsumer>
                    {({ formData: { primeTimeConfig } }) => (
                      <ArrayInput
                        source="newAddress.primeTimeConfig"
                        label="Prime Time"
                        className={classes.elInRow}
                        validate={[required()]}
                      >
                        <SimpleFormIterator>
                          <SelectInput
                            fullWidth
                            source="day"
                            label="Dzień"
                            validate={[required()]}
                            choices={[
                              {
                                id: 1,
                                name: 'Poniedziałek',
                              },
                              {
                                id: 2,
                                name: 'Wtorek',
                                disabled: checkDayAvailability(
                                  primeTimeConfig,
                                  2,
                                ),
                              },
                              {
                                id: 3,
                                name: 'Środa',
                                disabled: checkDayAvailability(
                                  primeTimeConfig,
                                  3,
                                ),
                              },
                              {
                                id: 4,
                                name: 'Czwartek',
                                disabled: checkDayAvailability(
                                  primeTimeConfig,
                                  4,
                                ),
                              },
                              {
                                id: 5,
                                name: 'Piątek',
                                disabled: checkDayAvailability(
                                  primeTimeConfig,
                                  5,
                                ),
                              },
                              {
                                id: 6,
                                name: 'Sobota',
                                disabled: checkDayAvailability(
                                  primeTimeConfig,
                                  6,
                                ),
                              },
                              {
                                id: 7,
                                name: 'Niedziela',
                                disabled: checkDayAvailability(
                                  primeTimeConfig,
                                  7,
                                ),
                              },
                            ]}
                          />
                          <TimeInput
                            fullWidth
                            source="fromSecond"
                            label="Od"
                            validate={[required()]}
                            options={{ format: 'HH:mm', ampm: false }}
                            format={(record) => {
                              if (record !== undefined && record !== null) {
                                return new Date(
                                  0,
                                  0,
                                  0,
                                  Math.floor(record / 3600),
                                  Math.floor((record % 3600) / 60),
                                );
                              }

                              return null;
                            }}
                            parse={timeParserFrom}
                          />
                          <TimeInput
                            fullWidth
                            source="toSecond"
                            label="Do"
                            validate={[required()]}
                            options={{ format: 'HH:mm', ampm: false }}
                            format={(record) => {
                              if (record) {
                                return new Date(
                                  0,
                                  0,
                                  0,
                                  Math.floor(record / 3600),
                                  Math.floor((record % 3600) / 60),
                                );
                              }
                              return null;
                            }}
                            parse={timeParser}
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                    )}
                  </FormDataConsumer>
                </div>
              )}
            </>
          )}
        </>
      );
    };

    return (
      <WrapperComponent
        {...props}
        redirect="list"
        title={kind === 'edit' ? 'Sala' : 'Tworzenie nowej sali'}
      >
        <SimpleForm
          submitOnEnter={false}
          toolbar={<FormToolbar kind={kind} />}
          redirect={(basePath) => basePath}
        >
          {kind === 'edit' && <EditionCancelModal />}
          <TextInput
            fullWidth
            source="name"
            label="Nazwa sali"
            validate={[required()]}
          />
          {kind === 'edit' ? (
            <CountryField source="inventory" />
          ) : (
            <SelectInput
              source="store"
              choices={Object.keys(COUNTRIES)
                .filter((c) => c !== COUNTRY_KEYS.FRANCE)
                .map((c) => ({
                  id: c,
                  name: COUNTRIES[c],
                }))}
              label="Lokalizacja sali"
              validate={[required()]}
              defaultValue={COUNTRY_KEYS.POLAND}
            />
          )}
          <ColorInput
            label="Kolor sali"
            source="color"
            validate={[required()]}
            disableAlpha
            picker="Chrome"
          />
          {kind === 'edit' && <TextField source="slug" label="URL slug" />}
          {renderAddressField()}
          <FormDataConsumer>
            {({ formData: { store } }) =>
              store === 'de' && (
                <>
                  <TextInput
                    source="paymentLink"
                    fullWidth
                    label="Payment Link"
                  />
                </>
              )
            }
          </FormDataConsumer>

          {permissions === ROLES_KEYS.ADMIN && (
            <ReferenceArrayInput
              label="Przypisani użytkownicy (Menedżerowie)"
              source="viewableBy"
              reference="users"
              fullWidth
              filter={{ role: ROLES_KEYS.ROOM_VIEWER }}
              options={{ className: 'separated-control' }}
            >
              <AutocompleteArrayInput
                optionText={(choice) => {
                  if (choice) {
                    if (
                      choice.billingAddress &&
                      choice.billingAddress.firstName &&
                      choice.billingAddress.lastName
                    ) {
                      return `${choice.billingAddress.firstName} ${choice.billingAddress.lastName} (${choice.email}) `;
                    }
                    return `${choice.name || ''} (${choice.email}) `;
                  }
                  return null;
                }}
              />
            </ReferenceArrayInput>
          )}
          <TabbedShowLayout className={classes.formTabs}>
            {LANGUAGES.map((lang) => (
              <Tab key={`lang-tab_${lang.key}`} label={lang.text}>
                <TextInput
                  source={`${lang.key}.name`}
                  label="Nazwa sali (tłumaczona), parametr opcjonalny"
                  fullWidth
                />
                <RichTextInput
                  label={`Dodatkowe informacje [${lang.text}]`}
                  source={`${lang.key}.additionalLocationInfo`}
                  styles={{ minHeight: 300 }}
                  validation={{ required: true }}
                  fullWidth
                />

                <TextInput
                  source={`${lang.key}.description`}
                  label="Opis"
                  fullWidth
                  multiline
                />
              </Tab>
            ))}
          </TabbedShowLayout>
          {kind === 'edit' ? (
            <CurrencyField source="inventory" />
          ) : (
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <SelectInput
                  source="currency"
                  choices={CURRENCY}
                  label="Waluta"
                  validate={[required()]}
                  initialValue={
                    formData.store === COUNTRY_KEYS.POLAND ? 'PLN' : 'EUR'
                  }
                  {...rest}
                />
              )
              // eslint-disable-next-line react/jsx-curly-newline
              }
            </FormDataConsumer>
          )}

          <FormDataConsumer className={classes.elInRow}>
            {({ formData, ...rest }) => (
              <ArrayInput
                source="pricing"
                label={`Cennik [${formData.currency}]`}
                validate={[required()]}
                {...rest}
              >
                <SimpleFormIterator>
                  <NumberInput
                    min="1"
                    source="grossPrice"
                    label="Cena za godzinę wynajmu"
                    validate={[required(), minValue(1)]}
                    format={convertPriceToPLN}
                    parse={convertPriceToGR}
                    fullWidth
                  />
                  <NumberInput
                    min="1"
                    source="maximumCapacity"
                    label="Maksymalna ilość osób"
                    validate={[required(), minValue(1), maxValue(9999)]}
                    fullWidth
                  />
                  <BooleanInput
                    label="Prime Time"
                    source="isPrimeTime"
                    defaultValue={false}
                    fullWidth
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )
            // eslint-disable-next-line react/jsx-curly-newline
            }
          </FormDataConsumer>
          <NumberInput
            min="1"
            source="capacity"
            label="Pojemność"
            type="number"
            validate={[minValue(1)]}
          />
          <NumberInput
            min="1"
            source="surfaceArea"
            label="Powierzchnia [m2]"
            validate={[required(), minValue(1)]}
          />
          <DateInput
            source="bookableTo"
            label="Data blokady rezerwacji"
            options={{ format: 'dd.MM.yyyy', cancelLabel: 'Anuluj' }}
            parse={(a) => {
              const foramtedDate = format(new Date(a), 'yyyy-MM-dd 23:59');
              return foramtedDate;
            }}
          />
          <ArrayInput
            source="availability"
            label="Dostępność"
            validate={[required()]}
            className={classes.elInRow}
          >
            <SimpleFormIterator>
              <SelectInput
                fullWidth
                source="day"
                label="Dzień"
                validate={[required()]}
                choices={[
                  { id: 1, name: 'Poniedziałek' },
                  { id: 2, name: 'Wtorek' },
                  { id: 3, name: 'Środa' },
                  { id: 4, name: 'Czwartek' },
                  { id: 5, name: 'Piątek' },
                  { id: 6, name: 'Sobota' },
                  { id: 7, name: 'Niedziela' },
                ]}
              />
              <TimeInput
                fullWidth
                source="fromSecond"
                label="Od"
                validate={[required()]}
                options={{ format: 'HH:mm', ampm: false }}
                format={(record) => {
                  if (record !== undefined && record !== null) {
                    return new Date(
                      0,
                      0,
                      0,
                      Math.floor(record / 3600),
                      Math.floor((record % 3600) / 60),
                    );
                  }

                  return null;
                }}
                parse={timeParserFrom}
              />
              <TimeInput
                fullWidth
                source="toSecond"
                label="Do"
                validate={[required()]}
                options={{ format: 'HH:mm', ampm: false }}
                format={(record) => {
                  if (record) {
                    return new Date(
                      0,
                      0,
                      0,
                      Math.floor(record / 3600),
                      Math.floor((record % 3600) / 60),
                    );
                  }
                  return null;
                }}
                parse={timeParser}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <ImageInput
            source="images"
            label="Zdjęcia"
            accept="image/*"
            multiple
            validate={[required()]}
          >
            <ImageFromApiField source="src" title="name" />
          </ImageInput>
          <MainImageFieldOptions />
          <ImageInput source="mapImage" label="Zdjęcie mapy" accept="image/*">
            <ImageFromApiField source="src" title="name" />
          </ImageInput>
        </SimpleForm>
      </WrapperComponent>
    );
  },
);

export default RoomForm;
