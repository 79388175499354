import React from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  DateField,
  FunctionField,
  CreateButton,
} from 'react-admin';
import { CardActions } from '@material-ui/core';

const ConvertToStatus = (record) => {
  switch (record.used) {
    case false:
      return 'Nieużyty';
    case true:
      return 'Użyty';
    default:
      return '';
  }
};

const NameField = ({ record = {} }) => (
  <span>
    {(record && record.name) ||
      (record.billingAddress &&
        (record.billingAddress.name ||
          `${record.billingAddress.firstName} ${record.billingAddress.lastName}`))}
  </span>
);
NameField.defaultProps = { label: 'Użytkownik' };

const DiscountsListActions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const DiscountsList = (props) => (
  <List
    {...props}
    title="Rabaty"
    bulkActionButtons={false}
    actions={<DiscountsListActions />}
    sort={{ field: '_id', order: 'desc' }}
  >
    <Datagrid rowClick="edit">
      <DateField source="validTo" label="Ważny do" showTime sortable={false} />
      <ReferenceField
        label="Użytkownik"
        source="_userId"
        reference="users"
        link={false}
        sortable={false}
      >
        <NameField />
      </ReferenceField>
      <FunctionField
        source="amount"
        label="Wartość rabatu"
        render={
          (record) =>
            record.type === 'amount' || record.type === 'amount_per_hour' ? (
              <div>
                {record.amount / 100} {record.currency || 'PLN'}
              </div>
            ) : (
              <div>{record.amount} %</div>
            )
          // eslint-disable-next-line react/jsx-curly-newline
        }
        sortable={false}
      />
      <FunctionField
        source="used"
        label="Status rabatu"
        render={(record) => (
          <div
            style={{
              color: record.used === true ? 'red' : '',
            }}
          >
            {ConvertToStatus(record)}
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
);

export default DiscountsList;
