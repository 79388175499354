/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import * as ReactColor from 'react-color';
import reactCSS from 'reactcss';

require('./ColorInput.css');

class ColorInputComponent extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    show: false,
    color: this.props.input.value,
  };

  handleOpen = () => this.setState({ show: true });

  handleClose = () => this.setState({ show: false });

  handleChange = ({ hex }) => {
    this.setState({ color: hex });
    this.props.input.onChange(hex);
    this.forceUpdate();
  };

  render() {
    const {
      label,
      source,
      meta,
      options,
      picker,
      input,
      resource,
      helperText,
      isRequired,
    } = this.props;

    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.state.color}`,
        },
        swatch: {
          marginTop: '10px',
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
      },
    });

    const { touched, error } = meta;

    const Picker = ReactColor[`${picker}Picker`];

    return (
      <div>
        <div className="MuiFormLabel-root MuiInputLabel-shrink">
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
            error={!!(touched && error)}
            helperText={(touched && error) || helperText}
          />
        </div>
        <div style={styles.swatch} onClick={this.handleOpen}>
          <div style={styles.color} />
        </div>
        {this.state.show ? (
          <div className="ColorInput-popup">
            <div className="ColorInput-cover" onClick={this.handleClose} />
            <Picker
              {...options}
              color={input.value}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorInputComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`),
};

ColorInputComponent.defaultProps = {
  picker: 'Chrome',
  options: {
    disableAlpha: true,
  },
};

export const ColorInput = addField(ColorInputComponent);
