/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  ExportButton,
  CreateButton,
  FunctionField,
  TextInput,
  SelectInput,
} from 'react-admin';
import { CardActions } from '@material-ui/core';
import { DateTimeInput } from '../../components/DateInputs';
import {
  PAYMENT_STATE,
  BOOKING_IS_CANCELLED,
  ROLES_KEYS,
} from '../../config/constants';

const dateTimeInputOptions = {
  ampm: false,
  keyboard: 'true',
  minutesStep: 30,
  mask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /[30]/,
    /0/,
  ],
  format: 'dd.MM.yyyy HH:mm:00',
  cancelLabel: 'Anuluj',
  variant: 'dialog',
  clearLabel: 'Wyczyść',
  clearable: true,
};

const BookingsFilters = (props) => (
  <Filter {...props}>
    <SelectInput
      source="paymentState"
      choices={[{ id: '', name: 'Wszystkie' }, ...PAYMENT_STATE]}
      label="Status płatności"
      alwaysOn
    />
    <SelectInput
      source="isCancelled"
      choices={BOOKING_IS_CANCELLED}
      label="Status rezerwacji"
      alwaysOn
    />
    <ReferenceInput
      source="bookableObjectId"
      reference="inventory"
      label="Nazwa sali"
      alwaysOn
      perPage={999}
    >
      <AutocompleteInput
        optionText={(choice) => {
          if (choice) {
            return `${choice.name}` === null || `${choice.name}` === 'undefined'
              ? 'Wszystkie'
              : `${choice.name}`;
          }
          return '';
        }}
      />
    </ReferenceInput>
    <TextInput source="name" label="Nazwa użytkownika" alwaysOn />
    <TextInput source="email" label="Email użytkownika" alwaysOn />
    <TextInput source="id" label="Nr. rezerwacji" alwaysOn />
    <TextInput source="shortCode" label="Skrócony nr. rezerwacji" alwaysOn />
    <TextInput source="description" label="Opis" alwaysOn />
    <DateTimeInput
      source="from"
      label="Od"
      options={dateTimeInputOptions}
      alwaysOn
    />
    {props.filterValues.from && (
      <DateTimeInput
        source="to"
        label="Do"
        options={{
          ...dateTimeInputOptions,
          format: 'dd.MM.yyyy HH:mm:00',
          minDate: props.filterValues.from,
          minutesStep: 30,
        }}
        alwaysOn
      />
    )}
  </Filter>
);

const PriceField = ({ record = {} }) => (
  <span>
    {record.priceData && (record.priceData.price / 100).toFixed(2)}&nbsp;
    {record.priceData && record.priceData.currency}
  </span>
);

PriceField.defaultProps = { label: 'Cena Rezerwacji' };

const NameField = ({ record = {} }) => {
  if (record.user) {
    return (
      <span>{record.user && record.user.name && `${record.user.name}`}</span>
    );
  }
  if (record.billingAddress) {
    return (
      <span>
        {(record.billingAddress &&
          `${record.billingAddress.firstName} ${record.billingAddress.lastName}`) ||
          record.billingAddress.name}
      </span>
    );
  }
  return '';
};
NameField.defaultProps = { label: 'Użytkownik' };

const PAYMENT_STATES = {
  NOT_PAID: 'Nieopłacone',
  PAID: 'Opłacone',
  PROCESSING: 'Przetwarzane',
  WILL_BE_PAID_IN_PERSON: 'Płatność w sali',
  FAILED: 'Błąd opłaty',
};

const BookingListActions = ({
  basePath,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

export const BookingsList = ({ permissions, ...props }) => (
  <div
    style={{
      display: 'flex',
      flex: '1 1 auto',
      overflowY: 'hidden',
      overflowX: 'scroll',
    }}
  >
    <List
      {...props}
      filters={<BookingsFilters />}
      title="Lista rezerwacji"
      bulkActionButtons={false}
      actions={<BookingListActions />}
      sort={{ field: '_id', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          label="Nazwa sali"
          source="_bookableObjectId"
          reference="inventory"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="_id" label="Nr. rezerwacji" sortable={false} />
        <TextField
          source="shortCode"
          label="Skrócony nr. rezerwacji"
          sortable={false}
        />
        <ReferenceField
          label="Miasto"
          source="_bookableObjectId"
          reference="inventory"
          link={false}
          sortable={false}
        >
          <ReferenceField
            label="Adres"
            source="_addressId"
            reference="addresses"
            link={false}
            sortable={false}
          >
            <TextField source="city" />
          </ReferenceField>
        </ReferenceField>
        {/* <TextField source="user.name" label="Użytkownik" sortable={false} /> */}
        <NameField source="bookings" sortable={false} />
        <TextField
          source="user.phone"
          label="Numer telefonu"
          sortable={false}
        />
        <DateField source="from" label="Od" showTime sortable={false} />
        <DateField source="to" label="Do" showTime sortable={false} />
        <TextField source="numberOfUsers" sortable={false} label="Ilość osób" />
        <PriceField source="bookings" sortable={false} />

        {permissions === ROLES_KEYS.ADMIN ? (
          <ReferenceField
            label="Status płatności"
            source="_orderId"
            reference="orders"
            sortable={false}
          >
            <FunctionField
              source="paymentState"
              label="Status płatności"
              render={(record) => (
                <div
                  style={{
                    color: record.paymentState === 'NOT_PAID' ? 'red' : '',
                  }}
                >
                  {PAYMENT_STATES[record.paymentState]}
                </div>
              )}
              sortable={false}
            />
          </ReferenceField>
        ) : (
          <FunctionField
            source="paymentState"
            label="Status płatności"
            render={(record) => (
              <div
                style={{
                  color: record.paymentState === 'NOT_PAID' ? 'red' : '',
                }}
              >
                {PAYMENT_STATES[record.paymentState]}
              </div>
            )}
            sortable={false}
          />
        )}

        <FunctionField
          source="isCancelled"
          label=""
          render={(record) => (
            <div
              style={{
                color: record.isCancelled === true ? 'red' : '',
              }}
            >
              {record.isCancelled ? 'Anulowana' : ''}
            </div>
          )}
          sortable={false}
        />
      </Datagrid>
    </List>
  </div>
);
export default BookingsList;
