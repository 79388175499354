import polyglotI18nProvider from 'ra-i18n-polyglot';
import polishMessages from 'ra-language-polish';
import englishMessages from 'ra-language-english';

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === 'pl' ? polishMessages : englishMessages),
  'pl',
);

export default i18nProvider;
