import React from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  SelectInput,
  NumberInput,
  required,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  minValue,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { FormSpy } from 'react-final-form';
import filter from 'lodash/filter';
import NavigationPrompt from 'react-router-navigation-prompt';
import { TimeInput } from '../../components/DateInputs';
import {
  convertPriceToPLN,
  convertPriceToGR,
} from '../../utils/priceConverter';
import DeleteButtonWithConfirmation from '../../layout/DeleteButtonWithConfirmation';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';
import { timeParserFrom, timeParser } from '../../utils/time';
import styles from '../../components/FormStyles';
import { validateTimeBy30 } from '../../utils/customValidators';

import { DISCOUNT_TYPE, COUNTRIES } from '../../config/constants';

const discountTypes = filter(DISCOUNT_TYPE, (dt) => dt.id !== 'amount');

const timeRangeValidate = [required(), validateTimeBy30];

const CountryField = ({ record = {} }) => (
  <span>{record.store && COUNTRIES[record.store]}</span>
);

CountryField.defaultProps = { label: 'Kraj', addLabel: true };

const CurrencyField = ({ record = {} }) => (
  <span>{record.currency && record.currency}</span>
);

CurrencyField.defaultProps = { label: 'Waluta', addLabel: true };

const EditToolbar = withStyles(styles)(({ dispatch, classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton label="Zapisz" {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

export const PromotionsEdit = withStyles(styles)(({ classes, ...props }) => {
  return (
    <Edit {...props} redirect="list" undoable={false} title="Adres">
      <SimpleForm submitOnEnter={false} toolbar={<EditToolbar />}>
        <FormSpy
          subscription={{
            modified: true,
          }}
        >
          {(spyProps) => (
            <>
              <NavigationPrompt when={isAnyModified(spyProps.modified)}>
                {({ onConfirm, onCancel }) => (
                  <ConfirmNavigationModal
                    when
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                  />
                )}
              </NavigationPrompt>
            </>
          )}
        </FormSpy>

        <TextInput
          source="name"
          label="Nazwa promocji"
          validate={[required()]}
          className={classes.input}
        />
        <SelectInput
          source="type"
          choices={discountTypes}
          label="Typ promocji"
          validate={[required()]}
          className={classes.input}
        />
        <TextInput
          source="description"
          label="Opis"
          validate={[required()]}
          className={classes.input}
          multiline
        />
        <CountryField source="promotion" />
        <CurrencyField source="promotion" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === 'amount' ||
            formData.type === 'amount_per_hour' ? (
              <>
                <NumberInput
                  source="amount"
                  label={`Wartość w [${formData.currency}]`}
                  validate={[required(), minValue(1)]}
                  format={convertPriceToPLN}
                  parse={convertPriceToGR}
                  min="1"
                  {...rest}
                  className={classes.input}
                />
              </>
            ) : (
              <NumberInput
                source="amount"
                label="Wartość w procentach"
                validate={[required(), minValue(1)]}
                min="1"
                {...rest}
                className={classes.input}
              />
            )
          // eslint-disable-next-line react/jsx-curly-newline
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <ArrayInput
              source="pricings"
              label="Detale promocji"
              validate={[required()]}
              className={classes.elInRow}
            >
              <SimpleFormIterator>
                <NumberInput
                  min="1"
                  source="validity_in_days"
                  label="Ważny przez (dni)"
                  validate={[required(), minValue(1)]}
                  className={classes.input}
                />
                <NumberInput
                  min="1"
                  source="price"
                  label={`Cena w ${formData.currency}`}
                  validate={[required(), minValue(1)]}
                  format={convertPriceToPLN}
                  parse={convertPriceToGR}
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </FormDataConsumer>

        <ArrayInput
          source="availability"
          label="Dostępność"
          validate={[required()]}
          className={classes.elInRow}
        >
          <SimpleFormIterator>
            <SelectInput
              fullWidth
              source="day"
              label="Dzień"
              validate={[required()]}
              choices={[
                { id: 1, name: 'Poniedziałek' },
                { id: 2, name: 'Wtorek' },
                { id: 3, name: 'Środa' },
                { id: 4, name: 'Czwartek' },
                { id: 5, name: 'Piątek' },
                { id: 6, name: 'Sobota' },
                { id: 7, name: 'Niedziela' },
              ]}
            />
            <TimeInput
              fullWidth
              source="fromSecond"
              label="Od"
              validate={[required()]}
              options={{ format: 'HH:mm', ampm: false }}
              format={(record) => {
                if (record !== undefined && record !== null) {
                  return new Date(
                    0,
                    0,
                    0,
                    Math.floor(record / 3600),
                    Math.floor((record % 3600) / 60),
                  );
                }

                return null;
              }}
              parse={timeParserFrom}
            />
            <TimeInput
              fullWidth
              source="toSecond"
              label="Do"
              validate={[required()]}
              options={{ format: 'HH:mm', ampm: false }}
              format={(record) => {
                if (record) {
                  return new Date(
                    0,
                    0,
                    0,
                    Math.floor(record / 3600),
                    Math.floor((record % 3600) / 60),
                  );
                }
                return null;
              }}
              parse={timeParser}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Ekskluzywna promocja" source="is_exclusive" />
        <NumberInput
          min="1"
          source="max_minutes_per_day"
          label="Minuty do wykorzystania danej promocji na dzień"
          validate={timeRangeValidate}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
});

export default PromotionsEdit;
