export const convertPriceToPLN = (v) => {
  let priceInPLN = '';
  if (v) {
    priceInPLN = v / 100;
  }
  return priceInPLN;
};

export const convertPriceToGR = (v) => {
  let priceInGR = '';
  if (v) {
    priceInGR = v * 100;
  }
  return priceInGR;
};
