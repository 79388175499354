import React from 'react';
import { Admin, Resource } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';

import { createBrowserHistory as createHistory } from 'history';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import addUploadFeature from './addUploadFeature';
import RoomList from './resources/Rooms/List';
import RoomForm from './resources/Rooms/Form';
import BookingsList from './resources/Bookings/BookingsList';
import BookingsEdit from './resources/Bookings/BookingsEdit';
import BookingsCreate from './resources/Bookings/BookingsCreate';
import UsersList from './resources/Users/List';
import UsersForm from './resources/Users/Form';
import AddressesList from './resources/Adresses/AddressesList';
// import AddressesCreate from './resources/Adresses/AddressesCreate';
// import AddressesEdit from './resources/Sdresses/AddressesEdit';
import AddressForm from './resources/Adresses/AddressForm';
import MatrixList from './resources/Matrix/MatrixList';
import i18nProvider from './i18nProvider';
import GlobalStyles from './config/globalStyles';
import LoginPage from './login/LoginPage';
import DiscountsList from './resources/Discounts/DiscountsList';
import DiscountsCreate from './resources/Discounts/DiscountsCreate';
import DiscountsEdit from './resources/Discounts/DiscountsEdit';
import PromotionsList from './resources/Promotions/PromotionsList';
import PromotionsEdit from './resources/Promotions/PromotionsEdit';
import PromotionsCreate from './resources/Promotions/PromotionsCreate';
import OrdersEdit from './resources/Orders/OrdersEdit';
import Menu from './Menu';
import BlogPostsList from './resources/BlogPosts/List';
import BlogPostsForm from './resources/BlogPosts/Form';
import SearchSettingsList from './resources/SearchSettings/List';
import SearchSettingsForm from './resources/SearchSettings/Form';

import ProductsForm from './resources/Products/Form';
import ProductsList from './resources/Products/List';

import { ROLES_KEYS } from './config/constants';

const dataProviderWithUploadFeature = addUploadFeature(dataProvider);
const history = createHistory();

const theme = createTheme({
  layoutAppFrame: {
    paddingTop: 68,
  },
  sidebar: {
    width: 220, // The default value is 240
    closedWidth: 70, // The default value is 55
  },
});

const App = () => (
  <>
    <GlobalStyles />
    <Admin
      menu={Menu}
      theme={theme}
      dataProvider={dataProviderWithUploadFeature}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      initialLocale="pl"
      history={history}
      loginPage={LoginPage}
    >
      <Resource
        name="matrix"
        options={{
          label: 'Grafik',
          permitted: [ROLES_KEYS.ADMIN, ROLES_KEYS.ROOM_VIEWER],
        }}
        list={MatrixList}
      />

      <Resource
        name="blog_posts"
        options={{
          label: 'Aktualności',
          permitted: [ROLES_KEYS.ADMIN],
        }}
        list={BlogPostsList}
        edit={(props) => <BlogPostsForm kind="edit" {...props} />}
        create={(props) => <BlogPostsForm kind="create" {...props} />}
      />

      <Resource
        name="products"
        options={{
          label: 'Sklep',
          permitted: [ROLES_KEYS.ADMIN],
        }}
        list={ProductsList}
        edit={(props) => <ProductsForm kind="edit" {...props} />}
        create={(props) => <ProductsForm kind="create" {...props} />}
      />

      <Resource
        name="addresses"
        options={{
          label: 'Lokalizacje',
          permitted: [ROLES_KEYS.ADMIN],
        }}
        list={AddressesList}
        edit={(props) => <AddressForm kind="edit" {...props} />}
        create={(props) => <AddressForm kind="create" {...props} />}
      />
      <Resource
        name="inventory"
        options={{
          label: 'Sale',
          permitted: [ROLES_KEYS.ADMIN, ROLES_KEYS.ROOM_VIEWER],
        }}
        list={RoomList}
        edit={(props) => <RoomForm kind="edit" {...props} />}
        create={(props) => <RoomForm kind="create" {...props} />}
      />

      <Resource
        name="users"
        options={{
          label: 'Klienci',
          permitted: [ROLES_KEYS.ADMIN],
        }}
        list={UsersList}
        edit={(props) => <UsersForm kind="edit" {...props} />}
        create={(props) => <UsersForm kind="create" {...props} />}
      />

      <Resource
        name="bookings"
        options={{
          label: 'Rezerwacje',
          permitted: [ROLES_KEYS.ADMIN, ROLES_KEYS.ROOM_VIEWER],
        }}
        list={BookingsList}
        edit={BookingsEdit}
        create={BookingsCreate}
      />

      <Resource
        name="settings"
        options={{
          label: 'Ustawienia',
          permitted: [ROLES_KEYS.ADMIN, ROLES_KEYS.ROOM_VIEWER],
        }}
        list={SearchSettingsList}
        edit={SearchSettingsForm}
      />

      <Resource
        name="discounts"
        options={{ label: 'Rabaty', permitted: [ROLES_KEYS.ADMIN] }}
        list={DiscountsList}
        edit={DiscountsEdit}
        create={DiscountsCreate}
      />

      <Resource
        name="promotions"
        options={{
          label: 'Premium',
          permitted: [ROLES_KEYS.ADMIN],
        }}
        list={PromotionsList}
        edit={PromotionsEdit}
        create={PromotionsCreate}
      />
      <Resource
        name="orders"
        options={{
          label: 'Zamówienia',
          permitted: [],
        }}
        edit={OrdersEdit}
      />
    </Admin>
  </>
);

export default App;
