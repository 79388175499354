/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  ArrayField,
  FunctionField,
  ExportButton,
  CreateButton,
  ReferenceField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { useForm } from 'react-final-form';

import { CardActions } from '@material-ui/core';
import { COUNTRIES, ROLES_KEYS } from '../../config/constants';
import { apiUrl } from '../../config/api';

const FullAdressField = ({ record = {} }) => (
  <span>
    {record.city}, {record.street}
  </span>
);
FullAdressField.defaultProps = { label: 'Adress' };

// const FullPriceField = ({ record = { pricePerHour: { gross: 0 } } }) => (
//   <span>
//     {record.pricePerHour &&
//       record.pricePerHour.gross &&
//       record.pricePerHour.gross.toFixed(2)}{' '}
//     {record.pricePerHour && record.pricePerHour.currency}{' '}
//   </span>
// );
// FullPriceField.defaultProps = { label: 'Cena za godzinę wynajmu' };

const FullPriceField = ({ record = { grossPrice: 0 } }) => {
  const price = record.grossPrice;
  return <span>{price && (price / 100).toFixed(2)} </span>;
};
FullPriceField.defaultProps = { addLabel: true };

const ImageFromApiField = ({ record = {}, source = '' }) => {
  const sourceParts = source.split('.');
  let url = record;
  for (const part of sourceParts) {
    url = url && url[part];
  }
  return <img style={{ width: '150px' }} src={`${apiUrl}/${url}`} alt="" />;
};
ImageFromApiField.defaultProps = { label: 'Zdjęcie' };

const SingleImageField = ({ record = {} }) => {
  const { images, primaryImageIndex: index } = record;
  if (images && index !== null)
    return (
      <ImageFromApiField record={images[index]} source="thumbnails.mini" />
    );
  return null;
};
SingleImageField.defaultProps = { addLabel: true };

const ConvertToDayOfWeek = (record) => {
  switch (record.day) {
    case 1:
      return 'Poniedziałek';
    case 2:
      return 'Wtorek';
    case 3:
      return 'Środa';
    case 4:
      return 'Czwartek';
    case 5:
      return 'Piątek';
    case 6:
      return 'Sobota';
    case 7:
      return 'Niedziela';
    default:
      return 'Błąd';
  }
};

const ConvertToTime = (field) => (record) => {
  const h = Math.floor(record[field] / 3600)
    .toFixed(0)
    .padStart(2, '0');
  const m = Math.ceil((record[field] % 3600) / 60)
    .toFixed(0)
    .padStart(2, '0');
  return `${h}:${m}`;
};

const ConvertTrueFalse = (field) => (record) => {
  switch (record[field]) {
    case true: {
      return 'Tak';
    }
    case false: {
      return 'Nie';
    }
    default:
      return 'Błąd';
  }
};

const CountryHelper = ({ formData, ...rest }) => {
  const form = useForm();

  return (
    <SelectInput
      source="store"
      choices={[
        { id: '', name: 'Wszystkie' },
        ...Object.keys(COUNTRIES).map((c) => ({
          id: c,
          name: COUNTRIES[c],
        })),
      ]}
      onChange={() => {
        form.change('addressId', null);
      }}
      label="Kraj"
      alwaysOn
      {...rest}
    />
  );
};

const RoomsFilters = (props) => {
  const { permissions } = usePermissions();

  if (permissions !== ROLES_KEYS.ADMIN) return null;

  return (
    <Filter {...props}>
      <FormDataConsumer form="filterForm" alwaysOn source="store">
        {(formDataProps) => <CountryHelper {...formDataProps} />}
      </FormDataConsumer>
      {props.filterValues.store && (
        <ReferenceInput
          source="addressId"
          reference="addresses"
          label="Lokalizacja"
          filter={{ country: props.filterValues.store }}
          alwaysOn
        >
          <AutocompleteInput
            optionText={(choice) => {
              if (choice) {
                return `${choice.city}` === null ||
                  `${choice.city}` === 'undefined'
                  ? 'Wszystkie'
                  : `${choice.city}, ${choice.street}`;
              }

              return null;
            }}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const RoomListActions = ({
  basePath,
  // currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      // sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

export const RoomsList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<RoomsFilters />}
      title="Lista sal"
      bulkActionButtons={false}
      actions={permissions === ROLES_KEYS.ADMIN ? <RoomListActions /> : null}
      sort={{ field: 'name', order: 'ASC' }}
      // filterDefaultValues={{ addressId: '5e26fc3aad47be5e5823a1a6' }}
    >
      <Datagrid rowClick={permissions === ROLES_KEYS.ADMIN ? 'edit' : null}>
        <TextField source="name" label="Nazwa sali" sortable={false} />
        <FunctionField
          source="store"
          label="Kraj"
          sortable={false}
          render={(record) => COUNTRIES[record.store]}
        />
        <ReferenceField
          label="Adres"
          source="_addressId"
          reference="addresses"
          link={false}
          sortable={false}
        >
          <FullAdressField source="addresses" />
        </ReferenceField>

        {/* <FunctionField
        source="pl.additionalLocationInfo"
        render={(r) => {
          if (r.pl && r.pl.additionalLocationInfo) {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: r.pl.additionalLocationInfo,
                }}
              />
            );
          }

          return null;
        }}
        label="Dodatkowe informacje"
        sortable={false}
      /> */}

        {/* <FullPriceField source="pricePerHour" sortable={false} /> */}

        <ArrayField source="pricing" label="Cennik">
          <Datagrid>
            <FullPriceField
              sortable={false}
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <FunctionField
                  source="currency"
                  render={(record) => (
                    <div
                      style={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontWeight: '300',
                        fontSize: '12px',
                      }}
                    >
                      Cena za godzinę wynajmu [{record.currency}]
                    </div>
                  )}
                  sortable={false}
                />
              }
            />
            <TextField
              source="maximumCapacity"
              label="Maksymalna ilość osób"
              sortable={false}
            />
            <FunctionField
              source="isPrimeTime"
              render={ConvertTrueFalse('isPrimeTime')}
              label="Prime Time"
              sortable={false}
            />
          </Datagrid>
        </ArrayField>

        <TextField source="capacity" label="Pojemność" sortable={false} />
        <TextField
          source="surfaceArea"
          label="Powierzchnia [m2]"
          sortable={false}
        />
        <ArrayField source="availability" label="Dostępność">
          <Datagrid>
            <FunctionField
              source="day"
              render={ConvertToDayOfWeek}
              label="Dzień"
              sortable={false}
            />
            <FunctionField
              source="fromSecond"
              render={ConvertToTime('fromSecond')}
              label="Od"
              sortable={false}
            />
            <FunctionField
              source="toSecond"
              render={ConvertToTime('toSecond')}
              label="Do"
              sortable={false}
            />
          </Datagrid>
        </ArrayField>
        <SingleImageField source="images" label="Zdjęcie główne" />

        {/* LIST OF IMAGES */}
        {/* <ArrayField source="images" label="Zdjęcia">
        <Datagrid>
          <ImageFromApiField
            source="src"
            label="Zdjęcie sali"
            sortable={false}
          />
        </Datagrid>
      </ArrayField> */}

        {/* MAP IMAGE */}
        {/* <ImageFromApiField
        source="mapImage.src"
        label="Zdjęcie mapy"
        sortable={false}
      /> */}
        {/* SINGLE IMAGE */}
        {/* <ImageFromApiField
        source="image03Path"
        label="Zdjęcie sali"
        sortable={false}
      /> */}
      </Datagrid>
    </List>
  );
};

export default RoomsList;
