import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNotify,
  useRedirect,
  fetchStart,
  fetchEnd,
  Button,
} from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';
import classnames from 'classnames';
import { withStyles, alpha } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { apiUrl } from '../config/api';

const styles = (theme) => ({
  cancelReservationButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

export const CancelBookingButton = withStyles(styles)(
  ({ record, classes, className }) => {
    const [displayDialog, setDisplayDialog] = useState(false);

    const handleClickOpen = () => {
      setDisplayDialog(true);
    };

    const handleClose = () => {
      setDisplayDialog(false);
    };
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
      setLoading(true);
      dispatch(fetchStart()); // start the global loading indicator

      try {
        fetch(`${apiUrl}/bookings/${record.id}/cancel`, {
          method: 'POST',
        })
          .then((response) => response.json())
          .then((resp) => {
            if (resp.status === 'error') {
              handleClose();
              notify(`Coś poszło nie tak. ${resp.message}`, 'warning');
            } else {
              notify('Rezerwacja została anulowana');

              let rp = '/bookings';
              if (localStorage.getItem('ballroom:referer')) {
                rp = localStorage.getItem('ballroom:referer');
                localStorage.removeItem('ballroom:referer');
              }

              redirect(rp);
            }
          })
          .catch((err) => {
            notify(`Coś poszło nie tak. ${err.message}`, 'warning');
          });
      } catch (error) {
        console.log({ error });
        notify('Błąd: comment not approved', 'warning');
      } finally {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      }
    };

    return (
      <>
        <Button
          label="Anuluj rezerwację"
          className={classnames(
            'ra-delete-button',
            classes.cancelReservationButton,
            className,
          )}
          onClick={handleClickOpen}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <Dialog
          fullWidth
          open={displayDialog}
          onClose={handleClose}
          aria-label="Are you sure?"
        >
          <DialogTitle>
            Czy chcesz na pewno chcesz anulować rezerwację?
          </DialogTitle>
          <DialogActions>
            <Button
              label="Anuluj rezerwację"
              className={classnames(
                'ra-delete-button',
                classes.cancelReservationButton,
                className,
              )}
              onClick={handleClick}
              disabled={loading}
            >
              <IconCancel />
            </Button>
            <Button label="Anuluj" onClick={handleClose}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
export default CancelBookingButton;
