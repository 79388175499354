import React from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  SelectInput,
  NumberInput,
  DateInput,
  required,
  ReferenceField,
  FormDataConsumer,
  minValue,
} from 'react-admin';
import { format } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import { FormSpy } from 'react-final-form';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  convertPriceToPLN,
  convertPriceToGR,
} from '../../utils/priceConverter';
import DeleteButtonWithConfirmation from '../../layout/DeleteButtonWithConfirmation';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';

import { DISCOUNT_TYPE } from '../../config/constants';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const AddresssEditToolbar = withStyles(styles)(
  ({ dispatch, classes, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" {...props} />
      <DeleteButtonWithConfirmation {...props} />
    </Toolbar>
  ),
);

const dateInputOptions = {
  ampm: false,
  keyboard: 'true',
  mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
  format: 'dd.MM.yyyy HH:mm',
  cancelLabel: 'Anuluj',
};
const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

const NameField = ({ record = {} }) => (
  <span>
    {(record && record.name) ||
      (record.billingAddress &&
        (record.billingAddress.name ||
          `${record.billingAddress.firstName} ${record.billingAddress.lastName}`))}
  </span>
);
NameField.defaultProps = { label: 'Użytkownik' };

export const DiscountsEdit = (props) => {
  return (
    <Edit {...props} redirect="list" undoable={false} title="Adres">
      <SimpleForm submitOnEnter={false} toolbar={<AddresssEditToolbar />}>
        <FormSpy
          subscription={{
            modified: true,
          }}
        >
          {(spyProps) => (
            <>
              <NavigationPrompt when={isAnyModified(spyProps.modified)}>
                {({ onConfirm, onCancel }) => (
                  <ConfirmNavigationModal
                    when
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                  />
                )}
              </NavigationPrompt>
            </>
          )}
        </FormSpy>
        <ReferenceField
          label="Użytkownik"
          source="_userId"
          reference="users"
          link={false}
          validate={[required()]}
        >
          <NameField />
        </ReferenceField>
        <TextInput
          source="marketingCode"
          label="Nazwa rabatu"
          validate={[required()]}
        />
        <SelectInput
          source="type"
          choices={DISCOUNT_TYPE}
          label="Typ rabatu"
          validate={[required()]}
        />
        <TextInput
          source="currency"
          label="Waluta"
          validate={[required()]}
          disabled
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === 'amount' ||
            formData.type === 'amount_per_hour' ? (
              <NumberInput
                source="amount"
                label={`Wartość w [${formData.currency || 'PLN'}]`}
                validate={[required(), minValue(1)]}
                format={convertPriceToPLN}
                parse={convertPriceToGR}
                min="1"
                {...rest}
              />
            ) : (
              <NumberInput
                source="amount"
                label="Wartość w procentach"
                validate={[required(), minValue(1)]}
                min="1"
                {...rest}
              />
            )
          // eslint-disable-next-line react/jsx-curly-newline
          }
        </FormDataConsumer>
        <DateInput
          source="validTo"
          label="Ważny do"
          options={dateInputOptions}
          validate={[required()]}
          parse={(a) => {
            const foramtedDate = format(new Date(a), 'yyyy-MM-dd 23:59');
            return foramtedDate;
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default DiscountsEdit;
