import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import jwtDecode from 'jwt-decode';
import { API, apiRoutes } from './config/api';

export const KEYS = {
  ROLE: 'admin:role',
  TOKEN: 'token',
  ADMIN_ID: 'admin:id',
};

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    // const request = new Request('http://localhost:5000/auth/sign_in', {
    //   method: 'POST',
    //   body: JSON.stringify({ email: username, password }),
    //   headers: new Headers({ 'Content-Type': 'application/json' }),
    // });
    // return fetch(request)
    //   .then((response) => {
    //     if (response.status < 200 || response.status >= 300) {
    //       throw new Error(response.statusText);
    //     }
    //     return response.json();
    //   })
    //   .then(({ token }) => {
    //     localStorage.setItem('token', token);
    //   });
    try {
      const {
        data: { token },
      } = await API.post(apiRoutes.auth.signIn, {
        email: username,
        password,
      });

      const JWTDecoded = jwtDecode(token);

      localStorage.setItem(KEYS.TOKEN, token);
      localStorage.setItem(KEYS.ROLE, JWTDecoded.role);
      localStorage.setItem(KEYS.ADMIN_ID, JWTDecoded.id);
      return Promise.resolve();
    } catch (error) {
      localStorage.removeItem('token');
      console.log(error);
      return Promise.reject();
    }
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem(KEYS.ROLE);
    return role ? Promise.resolve(role) : Promise.reject();
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    localStorage.removeItem(KEYS.ROLE);
    localStorage.removeItem(KEYS.ADMIN_ID);
    return Promise.resolve();
  }
  // called when the API returns and error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem(KEYS.TOKEN) &&
      localStorage.getItem(KEYS.ROLE) &&
      localStorage.getItem(KEYS.ADMIN_ID)
      ? Promise.resolve()
      : Promise.reject();
  }

  return Promise.resolve();
};
