import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { FormSpy } from 'react-final-form';
import NavigationPrompt from 'react-router-navigation-prompt';
import { timeParserFrom, timeParser } from '../../utils/time';
import ConfirmNavigationModal from '../../layout/ConfirmNavigationModal';
import formStyles from '../../components/FormStyles';
import { TimeInput } from '../../components/DateInputs';
import { COUNTRIES } from '../../config/constants';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const AddresssEditToolbar = withStyles(styles)(
  ({ dispatch, classes, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" {...props} />
    </Toolbar>
  ),
);

const isAnyModified = (modified) =>
  !!Object.keys(modified).find((key) => modified[key]);

export const checkDayAvailability = (blockedDays, day) => {
  if (!blockedDays) {
    return false;
  }

  return !!blockedDays.find((bd) => (bd ? bd.day === day : false));
};

export const AddressForm = withStyles(formStyles)(
  ({ classes, kind, ...props }) => {
    const WrapperComponent = kind === 'edit' ? Edit : Create;

    if (kind === 'edit') {
      props.undoable = false;
    }

    return (
      <WrapperComponent
        {...props}
        title={kind === 'edit' ? 'Lokalizacja' : 'Tworzenie nowej lokalizacji'}
      >
        <SimpleForm
          submitOnEnter={false}
          toolbar={<AddresssEditToolbar />}
          redirect={(basePath) => basePath}
        >
          {kind === 'edit' ? (
            <FormSpy
              subscription={{
                modified: true,
              }}
            >
              {(spyProps) => (
                <>
                  <NavigationPrompt when={isAnyModified(spyProps.modified)}>
                    {({ onConfirm, onCancel }) => (
                      <ConfirmNavigationModal
                        when
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                      />
                    )}
                  </NavigationPrompt>
                </>
              )}
            </FormSpy>
          ) : null}
          <SelectInput
            fullWidth
            source="country"
            label="Kraj"
            validate={[required()]}
            choices={Object.keys(COUNTRIES).map((c) => ({
              id: c,
              name: COUNTRIES[c],
            }))}
          />
          <TextInput
            label="Miasto"
            source="city"
            validate={[required()]}
            fullWidth
          />
          <TextInput
            label="Ulica"
            source="street"
            validate={[required()]}
            fullWidth
          />
          <BooleanInput label="Aktywny" source="active" defaultValue={false} />
          <FormDataConsumer>
            {({ formData: { primeTimeConfig } }) => (
              <ArrayInput
                source="primeTimeConfig"
                label="Prime Time"
                className={classes.elInRow}
                validate={[required()]}
              >
                <SimpleFormIterator>
                  <SelectInput
                    fullWidth
                    source="day"
                    label="Dzień"
                    validate={[required()]}
                    choices={[
                      {
                        id: 1,
                        name: 'Poniedziałek',
                      },
                      {
                        id: 2,
                        name: 'Wtorek',
                        disabled: checkDayAvailability(primeTimeConfig, 2),
                      },
                      {
                        id: 3,
                        name: 'Środa',
                        disabled: checkDayAvailability(primeTimeConfig, 3),
                      },
                      {
                        id: 4,
                        name: 'Czwartek',
                        disabled: checkDayAvailability(primeTimeConfig, 4),
                      },
                      {
                        id: 5,
                        name: 'Piątek',
                        disabled: checkDayAvailability(primeTimeConfig, 5),
                      },
                      {
                        id: 6,
                        name: 'Sobota',
                        disabled: checkDayAvailability(primeTimeConfig, 6),
                      },
                      {
                        id: 7,
                        name: 'Niedziela',
                        disabled: checkDayAvailability(primeTimeConfig, 7),
                      },
                    ]}
                  />
                  <TimeInput
                    fullWidth
                    source="fromSecond"
                    label="Od"
                    validate={[required()]}
                    options={{ format: 'HH:mm', ampm: false }}
                    format={(record) => {
                      if (record !== undefined && record !== null) {
                        return new Date(
                          0,
                          0,
                          0,
                          Math.floor(record / 3600),
                          Math.floor((record % 3600) / 60),
                        );
                      }

                      return null;
                    }}
                    parse={timeParserFrom}
                  />
                  <TimeInput
                    fullWidth
                    source="toSecond"
                    label="Do"
                    validate={[required()]}
                    options={{ format: 'HH:mm', ampm: false }}
                    format={(record) => {
                      if (record) {
                        return new Date(
                          0,
                          0,
                          0,
                          Math.floor(record / 3600),
                          Math.floor((record % 3600) / 60),
                        );
                      }
                      return null;
                    }}
                    parse={timeParser}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </WrapperComponent>
    );
  },
);

export default AddressForm;
