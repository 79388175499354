import React from 'react';
import {
  Edit,
  SimpleForm,
  required,
  SelectInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from '../../layout/DeleteButtonWithConfirmation';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const OrdersEditToolbar = withStyles(styles)(
  ({ dispatch, classes, redirect, ...props }) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton label="Zapisz" redirect="/bookings" {...props} />
      <DeleteButtonWithConfirmation redirect="/bookings" {...props} />
    </Toolbar>
  ),
);
const redirect = () => `/bookings`;

export const OrdersEdit = (props) => {
  return (
    <Edit {...props} redirect={redirect} undoable={false} title="Zamówienie">
      <SimpleForm submitOnEnter={false} toolbar={<OrdersEditToolbar />}>
        <SelectInput
          source="paymentState"
          label="Status płatności"
          validate={[required()]}
          choices={[
            { id: 'NOT_PAID', name: 'Nieopłacone' },
            { id: 'PAID', name: 'Opłacone' },
            { id: 'PROCESSING', name: 'Przetwarzane' },
            { id: 'WILL_BE_PAID_IN_PERSON', name: 'Płatność w sali' },
            { id: 'FAILED', name: 'Błąd opłaty' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default OrdersEdit;
