import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import {
  DatePicker,
  KeyboardTimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl';

const makePicker = (PickerComponent) => {
  const _makePicker = forwardRef((props, ref) => {
    const onChange = (date) => {
      const { input } = props;
      input.onChange(date);
      input.onBlur();
    };

    const {
      input,
      options,
      label,
      source,
      resource,
      isRequired,
      className,
      meta,
      providerOptions,
    } = props;

    const { touched, error } = meta;

    return (
      <div className="picker">
        <MuiPickersUtilsProvider {...providerOptions}>
          <PickerComponent
            {...options}
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            margin="normal"
            error={!!(touched && error)}
            helperText={touched && error}
            ref={ref}
            className={className}
            value={input.value ? input.value : null}
            onChange={onChange}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  });

  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  };

  _makePicker.defaultProps = {
    input: {},
    isRequired: 'false',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: DateFnsUtils,
      locale: plLocale,
    },
  };
  return _makePicker;
};

export const DateInput = addField(makePicker(DatePicker));
export const TimeInput = addField(makePicker(KeyboardTimePicker));
export const DateTimeInput = addField(makePicker(DateTimePicker));
