export const validateTimeBy30 = (value) => {
  if (value % 30 !== 0) {
    return 'Musi być wielokrotnością 30';
  }
  return undefined;
};

export const positiveVALUE = (value) => {
  if (value < 0) {
    return 'Must be over 18';
  }
  return undefined;
};
