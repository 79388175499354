import React from 'react';
import {
  Datagrid,
  List,
  ExportButton,
  CreateButton,
  TextField,
  FunctionField,
  useMutation,
  SelectInput,
  useRefresh,
  useNotify,
  Filter,
  usePermissions,
} from 'react-admin';
import { CardActions } from '@material-ui/core';
import { getHourFromSeconds } from '../../utils/time';
import { DAYS, COUNTRIES } from './constants';
import { ROLES_KEYS } from '../../config/constants';

const AddressFilters = (props) => {
  const { permissions } = usePermissions();

  if (permissions !== ROLES_KEYS.ADMIN) return null;
  return (
    <Filter {...props}>
      <SelectInput
        source="country"
        choices={[
          { id: '', name: 'Wszystkie' },
          ...Object.keys(COUNTRIES).map((c) => ({
            id: c,
            name: COUNTRIES[c],
          })),
        ]}
        label="Kraj"
        alwaysOn
      />
    </Filter>
  );
};

const AddressListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

const CountryNameCell = ({ country }) => {
  return COUNTRIES[country];
};

const PrimeTimeCell = ({ primeTimeConfig }) => {
  if (!primeTimeConfig) {
    return '-';
  }
  return primeTimeConfig.map((primeTime) => {
    const from = getHourFromSeconds(primeTime.fromSecond);
    const to = getHourFromSeconds(primeTime.toSecond);

    return (
      <div
        key={`prime-time-item_${primeTime.day}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <span style={{ fontSize: '12px', minWidth: 90 }}>
          {DAYS[primeTime.day]}:
        </span>{' '}
        <span>
          {from} - {to}
        </span>
      </div>
    );
  });
};

/* eslint-disable jsx-a11y/control-has-associated-label */
const ActiveCell = (address) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [setActive] = useMutation(
    {
      type: 'update',
      resource: 'addresses',
      payload: { id: address.id, data: { active: !address.active } },
    },
    {
      onSuccess: () => {
        refresh();
        notify('Lokalizacja została zaktualizowana', 'success');
      },
    },
  );

  const ActiveButtonClickHandler = (ev) => {
    ev.stopPropagation();
    setActive();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        type="button"
        style={{
          display: 'block',
          width: '16px',
          height: '16px',
          borderRadius: '100%',
          backgroundColor: address.active ? '#16cf00' : '#cf000a',
          border: 'none',
          cursor: 'pointer',
        }}
        onClick={ActiveButtonClickHandler}
      />
    </div>
  );
};

/* eslint-enable */
export const AddressesList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<AddressFilters />}
    title="Lista adresów"
    bulkActionButtons={false}
    e
    actions={permissions === ROLES_KEYS.ADMIN ? <AddressListActions /> : null}
    sort={{ field: 'city', order: 'DESC' }}
  >
    <Datagrid rowClick={permissions === ROLES_KEYS.ADMIN ? 'edit' : null}>
      <FunctionField label="Kraj" source="country" render={CountryNameCell} />
      <TextField source="city" label="Miasto" />
      <TextField source="street" label="Ulica" sortable={false} />

      <FunctionField
        source="active"
        label="Aktywny"
        sortable
        render={ActiveCell}
      />
      <FunctionField
        source="primeTimeConfig"
        label="Prime Time"
        sortable
        render={PrimeTimeCell}
      />
    </Datagrid>
  </List>
);

export default AddressesList;
