import React from 'react';
import {
  Datagrid,
  List,
  CreateButton,
  TextField,
  FunctionField,
  ArrayField,
} from 'react-admin';
import { CardActions } from '@material-ui/core';
import { convertPriceToPLN } from '../../utils/priceConverter';
import { COUNTRIES } from '../../config/constants';

const ConvertToStatus = (record) => {
  switch (record.is_exclusive) {
    case false:
      return 'Nie';
    case true:
      return 'Tak';
    default:
      return '';
  }
};

const DISCOUNT_TYPE = {
  // AMOUNT: 'AMOUNT',
  PERCENT: 'percent',
  AMOUNT_PER_HOUR: 'amount_per_hour',
};

const discountTypesMap = {
  amount_per_hour: 'Godzinowy',
  percent: 'Procentowy',
};

const ListActions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const PromotionsList = (props) => (
  <List
    {...props}
    title="Promocje"
    bulkActionButtons={false}
    actions={<ListActions />}
    sort={{ field: '_id', order: 'desc' }}
  >
    <Datagrid rowClick="edit">
      <FunctionField
        source="store"
        label="Kraj"
        sortable={false}
        render={(record) => COUNTRIES[record.store]}
      />
      <TextField source="name" label="Nazwa" sortable={false} />
      <FunctionField
        source="type"
        label="Typ rabatu"
        sortable={false}
        render={(record) => discountTypesMap[record.type]}
      />
      <FunctionField
        source="amount"
        label="Wartość rabatu"
        sortable={false}
        render={
          (record) => {
            switch (record.type) {
              case DISCOUNT_TYPE.AMOUNT_PER_HOUR:
                return (
                  <div>
                    {record.amount / 100} {record.currency}
                  </div>
                );
              case DISCOUNT_TYPE.AMOUNT:
                return <div>{record.amount} </div>;
              case DISCOUNT_TYPE.PERCENT:
                return <div>{record.amount}% </div>;
              default:
                return <div>{record.amount} </div>;
            }
          }
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
      <ArrayField source="pricings" label="Detale promocji">
        <Datagrid>
          <FunctionField
            source="validity_in_days"
            label="Ważny przez"
            sortable={false}
            render={(record) => `${record.validity_in_days} dni`}
          />
          <FunctionField
            source="price"
            label="Cena"
            render={(record) =>
              `${convertPriceToPLN(record.price)} ${record.currency || 'PLN'}`
            }
            sortable={false}
          />
        </Datagrid>
      </ArrayField>
      <FunctionField
        source="is_exclusive"
        label="Ekskluzywna promocja"
        render={(record) => (
          <div
            style={{
              color: record.is_exclusive === true ? 'green' : '',
            }}
          >
            {ConvertToStatus(record)}
          </div>
        )}
        sortable={false}
      />
    </Datagrid>
  </List>
);

export default PromotionsList;
