import React, { useEffect, useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useToggleSidebar } from 'react-admin';
import { withRouter } from 'react-router-dom';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { KEYS } from './authProvider';

const useStyles = makeStyles({
  icon: {
    height: 24,
    width: 24,
  },
});

const PromotionIcon = () => {
  const classes = useStyles();
  return (
    <img src="/images/icons/promotions.svg" className={classes.icon} alt="" />
  );
};

const CalendarIcon = () => {
  const classes = useStyles();
  return (
    <img src="/images/icons/calendar.svg" className={classes.icon} alt="" />
  );
};

const RoomsIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/rooms.svg" className={classes.icon} alt="" />;
};

const PointIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/point.svg" className={classes.icon} alt="" />;
};

const UsersIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/users.svg" className={classes.icon} alt="" />;
};

const NewsIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/news.svg" className={classes.icon} alt="" />;
};

const NotesIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/notes.svg" className={classes.icon} alt="" />;
};

const DiscountIcon = () => {
  const classes = useStyles();
  return (
    <img src="/images/icons/discount.svg" className={classes.icon} alt="" />
  );
};

const ProductsIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/cart.svg" className={classes.icon} alt="" />;
};

const SearchIcon = () => {
  const classes = useStyles();
  return <img src="/images/icons/search.svg" className={classes.icon} alt="" />;
};

const iconForResource = {
  matrix: CalendarIcon,
  addresses: PointIcon,
  inventory: RoomsIcon,
  users: UsersIcon,
  orders: CartIcon,
  bookings: NotesIcon,
  discounts: DiscountIcon,
  promotions: PromotionIcon,
  blog_posts: NewsIcon,
  products: ProductsIcon,
  settings: SearchIcon,
};

const Menu = ({ onMenuClick, logout }) => {
  const isMounted = useRef(null);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const userRole = useMemo(() => localStorage.getItem(KEYS.ROLE), []);
  const [sidebarIsOpen, toggleSidebar] = useToggleSidebar();

  useEffect(() => {
    const savedState = localStorage.getItem('blr:sidebarIsOpen');

    if (
      !isMounted.current &&
      savedState &&
      savedState !== sidebarIsOpen.toString()
    ) {
      toggleSidebar();
    }
    localStorage.setItem('blr:sidebarIsOpen', sidebarIsOpen);
  }, [sidebarIsOpen, toggleSidebar, isMounted]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <div className={`sidebar${!sidebarIsOpen ? ' sidebar--collapsed' : ''}`}>
      {resources.map((resource) => {
        if (resource.options.permitted.includes(userRole)) {
          const Icon = iconForResource[resource.name];
          return (
            <MenuItemLink
              className="menu-item"
              activeClassName="menu-item--active"
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                sidebarIsOpen
                  ? (resource.options && resource.options.label) ||
                    resource.name
                  : ''
              }
              leftIcon={<Icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        }
        return null;
      })}

      {isXSmall && logout}
    </div>
  );
};

export default withRouter(Menu);
