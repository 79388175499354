export const ROLES_KEYS = {
  USER: 'USER',
  PREMIUM_USER: 'PREMIUM_USER',
  ADMIN: 'ADMIN',
  ROOM_VIEWER: 'ROOM_VIEWER',
};

export const ROLES = [
  { id: ROLES_KEYS.USER, name: 'Użytkownik' },
  { id: ROLES_KEYS.PREMIUM_USER, name: 'Użytkownik premium' },
  { id: ROLES_KEYS.ADMIN, name: 'Admin' },
  { id: ROLES_KEYS.ROOM_VIEWER, name: 'Menedżer' },
];

export const USER_ROLES = [
  { id: ROLES_KEYS.USER, name: 'Użytkownik' },
  { id: ROLES_KEYS.PREMIUM_USER, name: 'Użytkownik premium' },
  { id: ROLES_KEYS.ADMIN, name: 'Admin' },
  { id: ROLES_KEYS.ROOM_VIEWER, name: 'Menedżer' },
];

export const LANGUAGES = [
  { key: 'pl', text: 'PL' },
  { key: 'en', text: 'EN' },
  { key: 'ua', text: 'UA' },
  { key: 'de', text: 'DE' },
  { key: 'es', text: 'ES' },
];

export const DISCOUNT_TYPE = [
  { id: 'amount', name: 'Rabat kwotowy' },
  { id: 'percent', name: 'Rabat procentowy' },
  { id: 'amount_per_hour', name: 'Rabat godzinowy' },
];

export const PAYMENT_STATE = [
  { id: 'NOT_PAID', name: 'Nieopłacone' },
  { id: 'PAID', name: 'Opłacone' },
  { id: 'PROCESSING', name: 'Przetwarzane' },
  { id: 'WILL_BE_PAID_IN_PERSON', name: 'Płatność w sali' },
  { id: 'FAILED', name: 'Błąd opłaty' },
];

export const BOOKING_IS_CANCELLED = [
  { id: '', name: 'Wszystkie' },
  { id: 'true', name: 'Anulowane' },
  { id: 'false', name: 'Aktywne' },
];

export const COUNTRY_KEYS = {
  FRANCE: 'fr',
  POLAND: 'pl',
  GERMAN: 'de',
};

export const COUNTRIES = {
  pl: 'Polska',
  de: 'Niemcy',
  fr: 'Francja',
};

export const CURRENCY = [
  { id: 'PLN', name: 'PLN' },
  { id: 'EUR', name: 'EUR' },
];
