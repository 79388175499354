import { startOfWeek, endOfWeek, formatISO } from 'date-fns';
// import Holidays from 'date-holidays';

// const hd = new Holidays('PL');

export function formatDate(date) {
  const d = new Date(date);
  return d.toLocaleDateString();
}

export function firstDayOfWeek() {
  return formatISO(startOfWeek(new Date(), { weekStartsOn: 1 }));
}
export function lastDayOfWeek() {
  return formatISO(endOfWeek(new Date(), { weekStartsOn: 1 }));
}

export function isAvailable({ availability }, { time }) {
  return availability
    ? time >= availability.fromSecond && time < availability.toSecond
    : false;
}

export function isToday(date) {
  const today = new Date();
  return today.toDateString() === new Date(date).toDateString();
}
