import React from 'react';
import { apiUrl } from '../../../config/api';

const ImageFromApiField = ({ record = {}, source = '' }) => {
  const sourceParts = source.split('.');
  let url = record;
  for (const part of sourceParts) {
    url = url && url[part];
  }
  if (!url.startsWith('blob:')) {
    url = `${apiUrl}/${url}`;
  }
  return <img style={{ maxHeight: '5rem' }} src={url} alt="" />;
};

ImageFromApiField.defaultProps = { label: 'Zdjęcie' };

export default ImageFromApiField;
